import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog/dialog.service';
// import { File } from '../../../timeline/types/file-old';
import { PopUpSubscriptBase } from '../../../../../../services/popup-service/popup_subscribe_services';
import { Subject } from 'rxjs';
import { EditDriverFormComponent } from '../../../timeline/components/edit-driver-form/edit-driver-form.component';
import { EditUserFormComponent } from '../edit-user-form/edit-user-form.component';
import { EditCarrierFormComponent } from '../../../timeline/components/edit-carrier-form/edit-carrier-form.component';
import { Attachment } from '../../../../../../_models/object/attachment';

@Component({
  selector: 'app-edit-user-popup',
  templateUrl: './edit-user-popup.component.html',
  styleUrl: './edit-user-popup.component.scss'
})
export class EditUserPopupComponent extends PopUpSubscriptBase implements OnInit, OnDestroy {
  @ViewChild(EditDriverFormComponent) driverFormComp!: EditDriverFormComponent;
  @ViewChild(EditUserFormComponent) userFormComp!: EditUserFormComponent;
  @ViewChild(EditCarrierFormComponent) carrierFormComp!: EditCarrierFormComponent;

  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<EditUserPopupComponent>
  ) {
    super(new Subject<any>());
  }

  public data: any;

  protected override showAPopUp(data: any): void {
    // console.log(data);
    this.data = data.data;
  }

  ngOnInit(): void { }

  override ngOnDestroy(): void {
    switch (this.data?.roles.join()) {
      case 'Driver': 
        this.driverFormComp.driverForm.reset(); 
        this.driverFormComp.driverForm.markAsPristine(); 
        this.driverFormComp.driverForm.markAsUntouched(); 
        this.driverFormComp.driverForm.updateValueAndValidity(); 
      break;
      case 'Carrier':
        this.carrierFormComp.carrierForm.reset(); 
        this.carrierFormComp.carrierForm.markAsPristine(); 
        this.carrierFormComp.carrierForm.markAsUntouched(); 
        this.carrierFormComp.carrierForm.updateValueAndValidity(); 
        break;
      default: 
        this.userFormComp.userForm.reset(); 
        this.userFormComp.userForm.markAsPristine(); 
        this.userFormComp.userForm.markAsUntouched(); 
        this.userFormComp.userForm.updateValueAndValidity(); 
      break;
    }
    super.ngOnDestroy();
  };

  public selectedTabIdx: number = 0;
  public formControl: any = {};
  public files: Array<Attachment> = new Array<Attachment>();

  handleFormValidity(childForm: any) {
    this.formControl = {...childForm.formData, isValid: childForm.isValid};    
  }

  addCoDriverClick(mainDriver: any) {
    this._result.next({type:"addCoDriver", formData: this.formControl});
  }

  editCodriverClick(mainDriver: any) {
    this._result.next({type:"editCodriver", formData: this.formControl});
  }

  handleFileChange(files: Array<Attachment>) {
    this.files = files;
  }

  deactivateClick() {
    this._result.next({type:"deactivate", formData: this.data});
  }

  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.dialogRef.close();
  }

  okBtnClick() {      

    this.formControl.files = this.files;
    // this.formControl.formData.newCarrierName = this.formControl.newCarrierName;
    this._result.next({type:"ok", formData: this.formControl});
  }
  
}
