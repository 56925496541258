import {MbscEventcalendarView} from '@mobiscroll/angular';

export const view: MbscEventcalendarView = {
    timeline: {
      size: 7,
      type: 'day',
      startTime: '00:00',
      endTime: '24:00',
      timeCellStep: 1440,
      timeLabelStep: 1440,
      currentTimeIndicator: true,
      startDay: 1,
      endDay: 5,
    }
}