import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscPopup,
  MbscPopupOptions,
} from '@mobiscroll/angular';
import { view } from '../../../../../utils/view';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Status } from '../../../../../enum/status.enum';
import { invalid } from '../../../../../utils/invalid';
import { loadColors, loadColorsForBackground } from '../types/load-status-color';
import { TooltipLoadInformation } from '../../../../../interfaces/tooltip-load-information';
import { SharedService } from '../../../../../services/sharedServices/shared.service';
import { SharedTimeService } from '../../../../../services/sharedServices/shared-time.service';
import { CreateModalAllComponent } from '../modal/create-modal-all/create-modal-all.component';
import { LoadsService } from '../../../../../services/loads/loads.service';
import { Dispatcher } from '../../../../../interfaces/loads/dispatcher';
import { ViewEditLoadComponent } from '../modal/edit/view-edit-load/view-edit-load.component';
import { EditUserPopupComponent } from '../../users/components/edit-user-popup/edit-user-popup.component';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { DialogComponent } from '../../../../../components/dialog/dialog.component';
import { UsersPageService } from '../../../../../services/users/users.service';
import { DriverNotePopupComponent } from '../modal/driver-note-popup/driver-note-popup.component';
import { EditTrailerPopupComponent } from '../../../edit-trailer-popup/edit-trailer-popup.component';
import { EditVehiclePopupComponent } from '../../../edit-vehicle-popup/edit-vehicle-popup.component';
import { SettingsComponent } from '../../../../../interfaces/settings-component';
import { combineLatest, debounceTime, finalize, firstValueFrom, forkJoin, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { DataService } from '../../../../../services/reference-data/reference-data.service';
import { Item } from '../../../../../interfaces/item';
import { TargetPopupComponent } from '../modal/target-popup/target-popup.component';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { CreateModalNoteComponent } from '../modal/create-modal-all/create-modal-note/create-modal-note.component';
import { UsersService } from '../../manage-pages/dispatch/services/users/users.service';
import { StorageService, StorageTempate } from '../../../../../services/storage/storage.service';
import { UserRolesCode } from '../../../../../enum/user-role.enum';
import { Driver, Entity, Fee, Load, LoadInfoAdress, Note } from '../../../../../_models';
import { TimelineService } from '../../../../../services/timeline/timeline.service';
import { DriverStatus } from '../../../../../enum/driver-status.enum';
import { OverlayPanel } from 'primeng/overlaypanel';
import moment from 'moment';
import { Carrier } from '../../../../../_models/object/carrier';
import { Dropdown } from 'primeng/dropdown';
import { ActionsFee } from '../../../../../enum/actionsFee';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-time-line-calendar',
  templateUrl: './time-line-calendar.component.html',
  styleUrls: ['./time-line-calendar.component.scss'],
})
export class TimeLineCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('filterResOverlay') filterResOverlay!: OverlayPanel;
  @ViewChild('popup', { static: false }) tooltip!: MbscPopup;
  @ViewChild('eventcalendar', { static: false }) eventcalendar!: MbscEventcalendar;
  @ViewChild('statusDrop') statusDrop!: Dropdown;

  @Input()
  set settingsFormHeader(value: any) {
   
    if (value) {
      this._settingsFormHeader = value;
      this.onSettingsChange();
    }
  }

  get settingsFormHeader(): SettingsComponent {
    return this._settingsFormHeader;
  }

  onSettingsChange() {
    // if(this.settingsFormHeader.hideOwned) {
    //   const filteredDrivers: Array<Driver> = this.copyDriver.filter(f=> f.$driverStatus !== DriverStatus.ownedDrivers);
    //   this.refreshResources(filteredDrivers);
    // }
  }

  private _settingsFormHeader!: SettingsComponent;
  isInitialized: boolean = false;
  itemsSubscription: Subscription = new Subscription();
  isMenuOpen: boolean = false;
  toolTipImformation!: TooltipLoadInformation;
  timelineSize: any;
  minDay: boolean = false;
  loads: Array<Load> = new Array<Load>();
  notes: Array<Note> = new Array<Note>();
  copyLoads: Array<Load> = new Array<Load>();
  allSelectedDispatchers: any[] = [];
  allSelectedCarriers: Array<string> = new Array<string>();
  coDriverTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end', // Center of the host element
      originY: 'center', // Center of the host element
      overlayX: 'start', // Start (left) of the overlay
      overlayY: 'center' // Center of the overlay
    }
  ];

  allDrivers: Array<Driver> = new Array<Driver>();
  allDispatchers: Dispatcher[] = [];
  allCarriers: Array<Carrier> = new Array<Carrier>();
  drivers: any[] = [];

  timeLineSize: number = 7;
  view: MbscEventcalendarView = {
    timeline: {
      ...view.timeline,
      size: this.timeLineSize,
    }  
  };
  weekendCellsColor: Array<MbscCalendarColor> = [
    {
      background: '#fafafb',
      recurring: { repeat: 'weekly', weekDays: 'SU' },
      cssClass: 'my-class',
    },
    {
      background: '#fafafb',
      recurring: { repeat: 'weekly', weekDays: 'SA' },
      cssClass: 'my-class',
    },
  ];

  public totalByloading: boolean = false;
  public totalByItems = [
    {
      label: 'Pick Up',
      disabled: false,
      command: () => this.calcPrice('PICKUP', true)
    },
    {
      label: 'Delivery',
      disabled: false,
      command: () => this.calcPrice('DELIVERY', true)
    }
  ];
  
  myResources: Array<Driver> = Array<Driver>();
  isDragLoad = false;
  myEvents: Array<Load | MbscCalendarEvent> = new Array<Load | MbscCalendarEvent>();
  newLoad: any;
  calculateTotalPriceBy: string = 'pickup';
  createLoadWindow = false;
  invalid = invalid;
  carrierId: string = 'all';

  timeLineStart: Date = this.getMonday();
  timeLineEnd!: Date;
  currentDate: Date = new Date();
  selectedDate: Date = new Date();
  copyDriver: Array<Driver> = Array<Driver>();
  originalDrivers: Array<Driver> = Array<Driver>();

  selectedCarrier: any;
  selectedDriver: any;
  createStartDate: any;
  createEndDate: any;
  showAllDriversOfSupervisor: any = {
    value: false,
    superID: null
  };

  readonly panelOpenState = signal(false);

  public searchLoadKey: string = '';
  public filterStateOn = false;

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
    themeVariant: 'light'
  };

  popupDelayTimer: any;

  timer: any;
  tooltipEvent: any;
  anchor?: HTMLElement;
  currentEvent: any;

  time: any;
  reason: any;
  location: any;

  settings!: SettingsComponent;

  calendarOptions: MbscEventcalendarOptions = {
    view: this.view,
    refDate: this.timeLineStart,
    dragToCreate: true,
    showEventTooltip: false,
    onEventHoverIn: (args) => {
      const event: any = args.event;
      if (event.type === 'Load') {
        this.toolTipImformation = {
          driverName: this.allDrivers.find(d=> d.$id === event.driverId)?.$driverFullName || '',
          carrierName: this.allDrivers.find(d=> d.$id === event.driverId)?.$carrierName || '',
          loadId: event.id,
          customerLoadId: event.customerLoadId,
          status: this.convertToTitleCase(event.status),
          price: event.price,
          fee: this.fee(event.fees[0]),
          feesLength: event.fees.length,
          pickUp: event.firstPickup.city + ", " + event.firstPickup.stateCode,
          delivery: event.lastDelivery.city + ", " + event.lastDelivery.stateCode,
          dh: event.emptyMiles | 0,
          miles: event.loadMiles,
          totalMiles: event.totalMiles,
          rate: event.ratePerMile.toFixed(2),
          duration: this.getHoursAndMinutes(event),
        };

        let obj = this.statusOptions.find(f=> f.$name.toLocaleLowerCase() === event.status.toLocaleLowerCase());
        if(obj) this.selectedStatus = new Entity(obj);
        
        this.anchor = event.status === 'TONU' ? args.domEvent.target.firstElementChild : args.domEvent.target; //fix tonu popup flickering
        this.popupDelayTimer = setTimeout(() => {
          if (this.popupDelayTimer) {
            this.tooltip.open();
            clearTimeout(this.popupDelayTimer)
            this.popupDelayTimer = undefined
          }
        }, 100)
      }
    },
    onEventHoverOut: () => {
      this.timer = setTimeout(() => {
        this.tooltip.close();
        this.tooltipEvent = null;
        this.isDropdownOpen = false
      }, 100);

      this.popupDelayTimer = undefined;
    },
    onEventClick: () => {
      this.tooltip.close();
      this.isDropdownOpen = false
    },
  };

  options: any = {
    refDate: this.timeLineStart,
  };

  tempFilters: {
    carrier?: string;
    dispatcher?: string;
  } = {};

  activeFilters: {
    carrier?: string;
    dispatcher?: string;
  } = {};

  selectedSortingByDriver: any;
  selectedSortingByDispather: any;
  mySelectedEvent: any;

  selectedStartDate: any;
  selectedEndDate: any;
  totalPrice!: number;
  totalMi!: number;
  totalRatePerMi!: number;
  totalByBtnTitle: string = 'PICKUP';

  isDropdownOpen = false;
  public statusOptions: Array<Entity> = new Array<Entity>();
  public selectedStatus: Entity = new Entity();

  private startSearchDate: any;

  public trailerTypeSource: Array<Item> = new Array<Item>();
  public fuelTypeSource: Array<Item> = new Array<Item>();
  public ownerTypeSource: Array<Item> = new Array<Item>();
  public makeTypeSource: Array<Item> = new Array<Item>();

  public DriverStatus = DriverStatus;
  
  intervalId: any;
  browserTime: string = '';
  openDropDownTotal: boolean = false;

  private subscription: Subscription = new Subscription();
  private calculateAllSubject = new Subject<[Array<Driver>, boolean]>();

  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private sharedTimelineService: SharedTimeService,
    private loadService: LoadsService,
    private dialogService: DialogService,
    private usersPageService: UsersPageService,
    private dataService: DataService,
    private usersService: UsersService,
    private storageService: StorageService,
    private sharedTimeService: SharedTimeService,
    private messageService: MessageService,
    private timelineService: TimelineService
  ) {
    this.calculateAllSubject.pipe(
      debounceTime(300),
      switchMap(([drivers, showSpinner]) => this.fetchTotalBy(drivers, showSpinner))
    ).subscribe();  
  }

  ngOnInit() {
    this.initTimeline();
  }

  initTimeline() {
    this.getStorage();
    this.startClock();
    this.statusOptions = Object.keys(Status).map((code, index) => (new Entity({id: index, name: code})));
    this.startItemsObserver();
    this.fetchTimelineData();
  }

  fetchTimelineData() {
    const formattedDate = new Date(this.timeLineStart).toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit'
    }).replace(/\//g, '-');

    const timelineData = this.timelineService.getAllTimelineData(formattedDate);
    const carriers = this.timelineService.getAllCarriers();
    const dispatchers = this.timelineService.getAllDispatchers();

    forkJoin([timelineData, carriers, dispatchers]).subscribe({
      next: ([timelineData, carriers, dispatchers]) => {
        this.allCarriers = carriers.map((carrier: Carrier) => new Carrier(carrier));
        this.allDispatchers = dispatchers;
        this.sharedService.setCarrier(this.allCarriers);
        this.sharedService.setDispatcher(dispatchers);
        this.timelineDataHanlder(timelineData);
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.afterTmlnFetchHandler();
      }
    });
  }

  timelineDataHanlder(timelineData: any) {
    this.loads = timelineData.loads.map((load: Load) => new Load(load));
    this.copyLoads = timelineData.loads.map((load: Load) => new Load(load));

    this.notes = timelineData.notes.map((note: Note) => new Note(note));
    
    this.sharedService.setLoad(this.loads);

    const mapDrivers = (driverArray: any[], driverStatus: DriverStatus): Driver[] => {
      return driverArray.map((driverData) => new Driver({...driverData, driverStatus: driverStatus}));
    };

    const driversData = {
      ownedDrivers: mapDrivers(timelineData.drivers.ownedDrivers, DriverStatus.ownedDrivers),
      sharedDrivers: mapDrivers(timelineData.drivers.sharedDrivers, DriverStatus.sharedDrivers),
      splitDrivers: mapDrivers(timelineData.drivers.splitDrivers, DriverStatus.splitDrivers),
      teamDrivers: mapDrivers(timelineData.drivers.teamDrivers, DriverStatus.teamDrivers),
      unassignedDrivers: mapDrivers(timelineData.drivers.unassignedDrivers, DriverStatus.unassignedDrivers),
      inactiveDrivers: mapDrivers(timelineData.drivers.inactiveDrivers, DriverStatus.inactiveDrivers),
    };

    this.allDrivers = Object.values(driversData).flat();

    this.copyDriver = Object.values(driversData).flat().map((driver: Driver) => new Driver(driver));
    this.originalDrivers = Object.values(driversData).flat().map((driver: Driver) => new Driver(driver));

    this.sharedService.setDriver(this.allDrivers);
    this.copyLoads = this.loads.filter(f=>f.$status !== 'DELETED');
  }

  show(data: any) {
    console.log(data);
  }

  async afterTmlnFetchHandler() {
    this.searchByFilteredDriver();
    this.searchLoad();
    this.setMonday();
    this.setMonth();
    this.shiftTimeline();
    this.onStartDateChange();
    this.onEndDateChange();
    await this.refreshResources(this.copyDriver);
    this.calculateAll(this.copyDriver);
    this.sharedService.setFilterDriver(this.allDrivers);
    this.isInitialized = true;
  }

  getMonthYear = (data: Date): string => {
    const options: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
    const formattedDate = data.toLocaleDateString('en-US', options);
    
    return formattedDate.replace(' ', ', ');
  };
  

  getDateDay(data: Date): number {
    return new Date(data).getDate();
  }

  getDateMonth(data: Date): string {
    return new Date(data).toLocaleString('default', { weekday: 'short' }).toUpperCase();
  }

  isLoadDurationLessThanFourHours(data: any): boolean {
    const start = new Date(data.dateTimeLineBgn).getTime();
    const end = new Date(data.dateTimeLineEnd).getTime();
    
    return end - start < 4 * 60 * 60 * 1000; // 4 hours in milliseconds
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.itemsSubscription.unsubscribe();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document
      .querySelectorAll('.event-tonu.mbsc-schedule-event.mbsc-ltr')
      .forEach((el) => {
        (el as HTMLElement).style.width = '0';
      });
      this.clearTrialLabels();
  }

  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe((items) => {
      items.map((item) => {
        switch (item.type) {
          case 'TrailerType':
            this.trailerTypeSource.push(item);
            break;
          case 'Ownership':
            this.ownerTypeSource.push(item);
            break;
          case 'Fuel':
            this.fuelTypeSource.push(item);
            break;
          case 'Make':
            this.makeTypeSource.push(item);
            break;
        }
      });
    });
  }

  onStartDateChange() {
    this.sharedTimelineService.startDateTimeString.subscribe((value) => {
      const date = value;
      if (date) {
        this.selectedStartDate = new Date(date);
      }
    });
  }

  onEndDateChange() {
    let subscribe = this.sharedTimelineService.endtDateTimeString.subscribe(async (value) => {
      const date = value;
      if (date) {
        const startDate: any = new Date(this.selectedStartDate);
        const endDate: any = new Date(date);
        this.timeLineEnd = endDate
        const differenceInMilliseconds = endDate - startDate;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);

        this.selectedEndDate = endDate;
        this.selectedDate = new Date(startDate);
        this.timeLineStart = new Date(startDate);
        this.options = {
          refDate: this.timeLineStart,
        };
        this.calendarOptions = {
          ...this.calendarOptions,
          refDate: this.timeLineStart,
        };
        await this.refreshResources(this.copyDriver);
        this.changeSize(differenceInDays + 1);
        await this.checkCurrentMonth();
      } else {
        this.selectedEndDate;
      }
      this.applySorting();
    });
    this.subscription.add(subscribe)
  }

  async refreshTimelineData(date: any) {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit'
    }).replace(/\//g, '-');

    let resp = await firstValueFrom(this.timelineService.getAllTimelineData(formattedDate));

    this.timelineDataHanlder(resp);

    // this.loads = resp.loads.map((load: Load) => new Load(load));
    // this.notes = resp.notes.map((note: Note) => new Note(note));

    // this.copyLoads = JSON.parse(JSON.stringify(this.loads));
    // this.sharedService.setLoad(this.loads);

    // const mapDrivers = (driverArray: any[]): Driver[] => {
    //   return driverArray.map((driverData) => new Driver(driverData));
    // };

    // const driversData = {
    //   ownedDrivers: mapDrivers(resp.drivers.ownedDrivers),
    //   teamDrivers: mapDrivers(resp.drivers.teamDrivers),
    //   sharedDrivers: mapDrivers(resp.drivers.sharedDrivers),
    //   splitDrivers: mapDrivers(resp.drivers.splitDrivers),
    //   unassignedDrivers: mapDrivers(resp.drivers.unassignedDrivers),
    //   inactiveDrivers: mapDrivers(resp.drivers.inactiveDrivers),
    // };

    // this.allDrivers = Object.values(driversData).flat();
    // this.copyDriver = JSON.parse(JSON.stringify(this.allDrivers));

    // this.sharedService.setDriver(this.allDrivers);

    // // this.copyLoads = this.loads.filter((el: any) => {
    // //   if (el.partial && el.partial.length) {
    // //     el.partial = el.partial.filter(
    // //       (partial: any) => partial.status !== 'DELETED'
    // //     );
    // //   }
    // //   return el.status !== 'DELETED';
    // // });

    // this.copyLoads = this.loads.filter(f=>f.$status !== 'DELETED');
  }

  async refreshTmlnDataResources() {
    // await this.getAllDrivers();
    await this.refreshTimelineData(this.timeLineStart);
    await this.refreshResources(this.copyDriver);
  }

  // async getAllDrivers() {
  //   let resp = await this.loadService.getAllDrivers(false);
  //   this.allDrivers = resp;
  //   this.copyDriver = JSON.parse(JSON.stringify(this.allDrivers));
  //   this.sharedService.setDriver(this.allDrivers);
  // }
  async getAllCarriers() {
    let allCarriers = await this.loadService.getAllCarriers();
    this.allCarriers = allCarriers;

    this.sharedService.setCarrier(this.allCarriers);
  }

  async getAllDispatchers() {
    let allDispatchers = await this.loadService.getAllDispatchers();
    this.allDispatchers = allDispatchers;

    this.sharedService.setDispatcher(this.allDispatchers);
  }

  public dragStart(e: any): void {
    e.event.type = 'mainLoad';
  }

  openFilterMenu(filterResOverlay: OverlayPanel, event: any) {
    filterResOverlay.show(event);
    setTimeout(() => {
      this.isMenuOpen = true;
    });
  }

  closeMenu(filterResOverlay: OverlayPanel) {
    this.isMenuOpen = false;
    filterResOverlay.hide();
  }

  adjustOverlayPosition() {
    if (this.filterResOverlay && this.filterResOverlay.container) {
      this.filterResOverlay.container.style.top = (parseInt(this.filterResOverlay.container.style.top, 10) - 15) + 'px';
      this.filterResOverlay.container.style.left = (parseInt(this.filterResOverlay.container.style.left, 10) - 1) + 'px';
    }
  }
  public async dragEnd(event: any) {
    this.createStartDate = event.event.start;
    this.createEndDate = event.event.end;
    try {
      const driver: Driver | undefined = this.allDrivers.find(
        (el) => el.$id === event.event.resource
      );
      const dispatcher = this.allDispatchers.find(
        (el) => el.id === driver?.$dispatcherId
      );
      let tempLoad = {
        start: event.event.start,
        end: event.event.end,
        driverId: event.event.resource,
        carrierId: 0,
        dispatcher,
      };
      let idx = this.allDrivers.findIndex((el) => el.$id === tempLoad.driverId);
      if (idx >= 0) {
        tempLoad.carrierId = this.allDrivers[idx].$carrierId;
      }
      this.isDragLoad = true;
      this.newLoad = tempLoad;
      this.createLoad(0, tempLoad);
    } catch (e) {
      console.log(e);
      await this.refreshTmlnDataResources()
    }
  }

  public calculateAll(drivers: Array<Driver>, showSpinner: boolean = false) {
    this.calculateAllSubject.next([drivers, showSpinner]); 
  }
  
  private fetchTotalBy(drivers: Array<Driver> ,showSpinner: boolean) {
    this.myResources.forEach((driver: Driver) => {
      driver.$price = 0;
      driver.$loadMiles = 0;
      driver.$rate = 0;
    });
  
    if (drivers) {
      let obj = {
        from: this.loadService.parseStartDate(this.timeLineStart),
        to: this.loadService.parseEndDate(this.selectedEndDate),
        type: this.totalByBtnTitle,
        drivers: this.getDriverAndAllLoadsIds(drivers),
      };
  
      if(showSpinner) this.totalByloading = true; 
      return this.loadService.getTotalBy(obj).pipe(
        tap((response: any) => {
          if (response) {
            if (response.driversTotalBys.length !== 0) {
              response.driversTotalBys.forEach((driverData: any) => {
                const driver = this.myResources.find(d => d.$id === driverData.driverId);
                if (driver) {
                  driver.$price = driverData.gross;
                  driver.$loadMiles = driverData.totalMiles;
                  driver.$rate = driverData.grossPerMile;
                }
              });
            }
            this.totalPrice = response.summaryTotalBy.gross;
            this.totalMi = response.summaryTotalBy.totalMiles;
            this.totalRatePerMi = response.summaryTotalBy.grossPerMile;
          }
          this.clearTrialLabels();
        }),
        finalize(() => {
          if(showSpinner) this.totalByloading = false; 
        })
      );
    }
  
    return of([]);
  }
  

  // public calculateAll(drivers: Array<Driver>) {
  //   this.myResources.forEach((driver: Driver) => {
  //     driver.$price = 0;
  //     driver.$loadMiles = 0;
  //     driver.$rate = 0;
  //   });

  //   if (drivers) {
  //     let obj = {
  //       from: this.loadService.parseStartDate(this.timeLineStart),
  //       to: this.loadService.parseEndDate(this.selectedEndDate),
  //       type: this.totalByBtnTitle,
  //       drivers: this.getDriverAndAllLoadsIds(drivers),
  //     };
  //     this.loadService.getTotalBy(obj).subscribe((response) => {
  //       if (response) {
  //         if (response.driversTotalBys.length !== 0) {
  //           for (let i = 0; i < response.driversTotalBys?.length; i++) {
  //             this.myResources.forEach((driver: Driver) => {
  //               if (response.driversTotalBys[i].driverId === driver.$id) {
  //                 driver.$price = response.driversTotalBys[i].gross;
  //                 driver.$loadMiles = response.driversTotalBys[i].totalMiles;
  //                 driver.$rate = response.driversTotalBys[i].grossPerMile;
  //               }
  //             });
  //           }
  //         }
  //       }
  //       this.totalPrice = response.summaryTotalBy.gross;
  //       this.totalMi = response.summaryTotalBy.totalMiles;
  //       this.totalRatePerMi = response.summaryTotalBy.grossPerMile;
  //     });
  //     this.clearTrialLabels();
  //   }
  // }

  isValidEvent(event: any): boolean {
    return event.original.status !== 'TONU' &&
           event.original.status !== 'DELETED' &&
           event.original.type !== 'Note';
  }
  
  getEventStyles(event: any): any {
    return {
      width: 'auto',
      margin: '3px 1px 0 1px',
      height: !event.original.status ? '47px' : '',
      'border-left': event.original.status ? this.checkStatus(event) : 'none',
      'background-color': !event.original.status ? '#d9d9d9' : 'transparent'
    };
  }
  
  getEventIcons(event: any): { src?: string; text?: string }[] {
    const icons: { src?: string; text?: string }[] = [];
  
    if (this.shouldDisplayLocationIcon(event)) {
      icons.push({ src: 'assets/svg/location-img.svg' });
    }
    if (event.original.moreThanOneDay && event.original.isParent) {
      icons.push({ src: 'assets/svg/car-img.svg' });
    }
    if (event.original.fees && event.original.fees.length > 0) {
      icons.push({ src: 'assets/svg/fees-img.svg' });
    }
    if (event.original.isSplit) {
      icons.push({ text: 'SPLIT' });
    }
  
    return icons;
  }    

  getDriverAndAllLoadsIds(driver: Array<Driver>) {
    
    let drivers: any = [
      {
        driverId: 0,
        loadIds: []
      }
    ];
    
    drivers = driver.map((d: Driver) => {
      let loadsId = d.$allLoads
        .filter((value: Load) => value.$type === "Load")
        .map((value: Load) => value.$id);
    
      return {
        driverId: d.$id,
        loadIds: loadsId
      };
    });
    
    return drivers;
    
  }

  addCalcLoad(resource: any) {
    //Alexander logic
    let endDate = new Date(this.timeLineStart);
    endDate.setDate(endDate.getDate() + (this.timeLineSize - 1));
    let loadsId = resource.allLoads?.map((value: any) => value.loadId)
    let obj = {
      from: this.timeLineStart,
      to: endDate,
      type: this.totalByBtnTitle,
      drivers: [{
        driverId: resource.id,
        loadIds: loadsId
      }],
    };

    // const timeLineDays = (): number => {
    //   let fromDate = new Date(this.timeLineStart);
    //   let toDate = new Date(endDate);    
    //   let differenceInTime = toDate.getTime() - fromDate.getTime();
    //   let differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)) + 1;
    //   return differenceInDays;
    // }

    this.loadService.getTotalBy(obj).subscribe((totalbyData) => {
      let driverTarget = totalbyData.driversTotalBys.filter((f: any) => f.driverId === resource.id);     
      
      if (driverTarget.length && driverTarget.length > 0) this.openTargetPopup(resource, driverTarget[0]);
      // else this.openTargetPopup(resource, { targets: [], offDays: timeLineDays()});
    });

  }

  openTargetPopup(resource: any, totalbyData: any) {

    let targetData = { resource: resource, totalbyData: totalbyData };
    let dialogRef: MatDialogRef<TargetPopupComponent> = this.dialog.open(
      TargetPopupComponent,
      {
        data: targetData,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.updateTarget(result.data, resource.id);
    });
  }

  checkIfExistFullAddress(): boolean {
    for (let i = 0; i > this.loads.length; i++) {
    }
    return false;
  }

  calcPrice(type: string, showSpinner: boolean) {
    this.totalByBtnTitle = type;
    this.openDropDownTotal = false;
    this.calculateAll(this.copyDriver, showSpinner);
  }

  public checkStatus(event: any): string {
    const eventDate = new Date(event.original.dateTimeLineBgn);
    const timeLineStartDate = new Date(this.timeLineStart);

    if (eventDate.getMonth() < timeLineStartDate.getMonth() ||
      (eventDate.getMonth() === timeLineStartDate.getMonth() && eventDate.getDate() < timeLineStartDate.getDate())) {
      return 'none';
    } else {
      return '7px solid ' + loadColors[event.original.status];
    }
  }

  checkStatusForBackground(status: any) {
    const lowerCaseStatus = status.toLowerCase();
    const lowerCaseColors = Object.keys(loadColorsForBackground).reduce((acc, key) => {
        acc[key.toLowerCase()] = loadColorsForBackground[key];
        return acc;
    }, {} as Record<string, string>);

    return lowerCaseColors[lowerCaseStatus] || '#d9d9d9';
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const clickedInside = (event.target as HTMLElement).closest('.main-container-status-dropdown-menu');
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.tooltip.open();
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
      this.isDropdownOpen = false
    }, 200);
  }

  public getInitials(fullDriverName: string) {
    let parts = fullDriverName.trim().split(/\s+/);
    let initials = parts
      .slice(0, 2)
      .map((part: string) => part.charAt(0))
      .join('');
    return initials.toUpperCase();
  }

  private getMonday(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff));
  }

  protected async refreshResources(drivers: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.myResources = drivers;
        this.myResources.forEach((el) => {
          el.$carrierId = el.$carrierId;
          el.$id = el.$id;
          el.$price = 0;
          el.$loadMiles = 0;
          el.$rate = 0;
          el.$allLoads = [];
        });
        this.myEvents = [];
  
        let loadsAndNotes: Array<Load | Note> = [...this.copyLoads, ...this.notes];
  
        loadsAndNotes.forEach((el: Load | Note) => {
          let tempLoad;
          let tempNote;
          try {
            if (el instanceof Load) {
              tempLoad = {
                title: el.$customerLoadId,
                start: el.$dateTimeLineBgn,
                end: el.$dateTimeLineEnd,
                resource: el.$driverId,
                fees: el.$fees,
                type: el.$type,
                moreThanOneDay:
                  new Date(el.$dateTimeLineEnd).getTime() -
                  new Date(el.$dateTimeLineBgn).getTime() >
                  82800000,
              };
            }
  
            if (el instanceof Note) {
              tempNote = {
                start: el.$dateTimeLineBgn,
                end: el.$dateTimeLineEnd,
                resource: el.$driverId,
                type: el.$type,
                moreThanOneDay:
                  new Date(el.$dateTimeLineEnd).getTime() -
                  new Date(el.$dateTimeLineBgn).getTime() >
                  82800000,
              };
            }
            if (el.$type !== 'Partial') {
              this.myEvents.push({ ...el, ...tempNote, ...tempLoad, allDay: false });
            }
  
            const idx = this.myResources.findIndex(
              (item: Driver) => item.$id === el.$driverId
            );
  
            if (idx >= 0) {
              this.myResources[idx].$allLoads.push(new Load(el));
            }
          } catch (e) {
            console.log(e);
          }
        });
  
        if (this.isInitialized) {
          this.calculateAll(drivers);
        }
        this.clearTrialLabels();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }  

  shouldDisplayLocationIcon(event: any): boolean {
    const pickup: LoadInfoAdress = event.original.firstPickup;
    const delivery: LoadInfoAdress = event.original.lastDelivery;
    if (event && event.original && event.original.moreThanOneDay && pickup && delivery) {
      // Check if any of the required fields are missing in firstPickup or lastDelivery
      const isFirstPickupIncomplete = !pickup.$city ||
                                      !pickup.$street ||
                                      !pickup.$zipCode;
  
      const isLastDeliveryIncomplete = !delivery.$city ||
                                       !delivery.$street ||
                                       !delivery.$zipCode;
  
      // Return true if either firstPickup or lastDelivery is incomplete
      return isFirstPickupIncomplete || isLastDeliveryIncomplete;
    }
  
    // Return false if any of the initial conditions are not met
    return false;
  }

  getMinDay(startDate: any, minDate: any) {
    let date1: any = new Date(startDate);
    let date2: any = new Date(minDate);
    let timediferens = date2 - date1;
    let differens = Math.floor(timediferens / (1000 * 60 * 60 * 24));
    if (differens > 1) {
      this.minDay = true;
    }
    this.minDay = false;
  }

  selectedCarriers(data: any) {
    const check = data.event.checked;
    const carrierName = data.carrierName

    if (check) {
      this.tempFilters.carrier = carrierName;
      this.allSelectedCarriers.push(carrierName);

      this.allCarriers.forEach(car => {
        if (this.allSelectedCarriers.includes(car.$name)) {
          car.$selectedCarrier = true;
        }
      });
    } else {
      let index = this.allSelectedCarriers.indexOf(carrierName);
      if (index !== -1) {
        this.allSelectedCarriers.splice(index, 1);
      }

      this.allCarriers.forEach(car => {
        if (this.allSelectedCarriers.includes(car.$name)) {
          car.$selectedCarrier = true;
        } else {
          car.$selectedCarrier = false;
        }
      });

      // delete this.tempFilters.carrier;
    }
  }

  selectedDispatcher(event: any) {
    if (event) {
      this.tempFilters.dispatcher = event.target.value;
      this.allSelectedDispatchers.push(event.target.value);
      this.allDispatchers.forEach((dispatcher) => {
        if (
          this.allSelectedDispatchers.includes(dispatcher.dispatcherFullName)
        ) {
          dispatcher.selectedDispatcher = true;
        }
      });
    } else {
      let index = this.allSelectedDispatchers.indexOf(event.target.value);
      this.allSelectedDispatchers.splice(index, 1);
      this.allDispatchers.forEach((dispatcher) => {
        if (
          this.allSelectedDispatchers.includes(dispatcher.dispatcherFullName)
        ) {
          dispatcher.selectedDispatcher = true;
        } else {
          dispatcher.selectedDispatcher = false;
        }
      });
      // delete this.tempFilters.dispatcher;
    }
  }

  applySorting = (filterResOverlay?: OverlayPanel) => {
    // let filteredDrivers: Array<Driver> = this.copyDriver.map((driver: Driver) => new Driver(driver));
    let filteredDrivers: Array<Driver> = [...this.copyDriver];
    this.filterStateOn = false;
    // filteredDrivers = this.filterDriverBySuperID(filteredDrivers);
  
    this.activeFilters = { ...this.tempFilters };
  
    this.storageService.updateStorageField('selectedSortingByDriver', this.selectedSortingByDriver);
    this.storageService.updateStorageField('selectedSortingByDispather', this.selectedSortingByDispather);
    this.storageService.updateStorageField('showAllDriversOfSupervisor', this.showAllDriversOfSupervisor);
    this.storageService.updateStorageField('companySort', this.allCarriers);

    if (this.selectedSortingByDriver != null && this.selectedSortingByDriver != -1) {
      filteredDrivers = filteredDrivers.sort((a: Driver, b: Driver) => {
        const nameA = a.$driverFullName?.toLowerCase() || "";
        const nameB = b.$driverFullName?.toLowerCase() || "";
  
        return this.selectedSortingByDriver === 0
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
      this.filterStateOn = true;
    }

    if (this.selectedSortingByDispather != null && this.selectedSortingByDispather != -1) {
      filteredDrivers = filteredDrivers.sort((a: Driver, b: Driver) => {
        const nameA = a.$dispatcherFullName?.toLowerCase() || "";
        const nameB = b.$dispatcherFullName?.toLowerCase() || "";
  
        return this.selectedSortingByDispather === 0
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
      this.filterStateOn = true;
    }

    filteredDrivers = filteredDrivers.sort((a: Driver, b: Driver) => {
      const aSelected = this.allCarriers.some((carrier: Carrier) => carrier.$selectedCarrier && carrier.$name === a.$carrierName);
      const bSelected = this.allCarriers.some((carrier: Carrier) => carrier.$selectedCarrier && carrier.$name === b.$carrierName);
  
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

    if (this.allCarriers.some((carrier: Carrier) => carrier.$selectedCarrier)) {
      this.filterStateOn = true;
    }

    this.copyDriver = filteredDrivers;
    this.myResources = [...this.copyDriver];
    this.refreshResources(this.copyDriver);
  
    this.isMenuOpen = false;
    if (filterResOverlay) filterResOverlay.hide();
  };

  filterDriverBySuperID(filteredDrivers: Array<Driver>): Array<Driver> {
    if (this.showAllDriversOfSupervisor.value) {
      this.originalDrivers = this.copyDriver.map((driver: Driver) => new Driver(driver));
      filteredDrivers = this.copyDriver.filter((driver: Driver) => driver.$dispatcherId === this.showAllDriversOfSupervisor.superID);
      return filteredDrivers;
    } else {
      // if(filteredDrv) filteredDrivers = [...this.copyDriver];
      filteredDrivers = [...this.copyDriver];
      return filteredDrivers;
    }
  }
  
  getStorage() {
    let storage = this.storageService.getStorage();
    if (
      storage?.start || 
      storage?.selectedSortingByDriver !== -1 ||
      storage?.selectedSortingByDispather !== -1 || 
      storage?.companySort.length !== 0 || 
      storage?.showAllDriversOfSupervisor?.value
    ) {
      this.selectedSortingByDispather = storage?.selectedSortingByDispather
      this.selectedSortingByDriver = storage?.selectedSortingByDriver
      this.allCarriers = storage?.companySort || this.allCarriers;
      this.showAllDriversOfSupervisor = storage?.showAllDriversOfSupervisor;
      this.timeLineStart = this.currentDate = new Date(storage?.start)
      this.applySorting();
    }
  }

  getDriversOfSupervisor() {
    // this.copyDriver = await this.loadService.getAllDrivers(
    //   this.showAllDriversOfSupervisor
    // );
  }

  resetFilter() {
    this.tempFilters = {};
    this.activeFilters = {};
    this.selectedSortingByDriver = null;
    this.selectedSortingByDispather = null;
    this.showAllDriversOfSupervisor = {};
    this.allCarriers.forEach((value) => (value.$selectedCarrier = false));
    this.allDispatchers.forEach((value) => (value.selectedDispatcher = false));
    this.refreshResources(this.copyDriver);
  }

  searchByFilteredDriver() {
    let subscribe = this.sharedService.filteredDriver.subscribe((filteredData) => {
      if (this.isInitialized) {
        if (filteredData) {
          this.copyDriver = filteredData;
        } else {
          this.copyDriver = this.allDrivers
        }
        this.applySorting();
        // this.refreshResources(this.copyDriver);
      }
    });
    this.subscription.add(subscribe)
  }

  searchLoad() {
    let subscribe = combineLatest([
      this.sharedService.fiteredLoads,
      this.sharedService.shouldUpdateCalendar$
    ]).subscribe(([filteredDataLoad, shouldUpdate]) => {
      if (this.isInitialized) {
        if (filteredDataLoad.length !== this.loads.length) {
          let loadsIds = filteredDataLoad.map(value => value.driverId);
          let selectedDrivers = this.copyDriver.filter((value: any) =>
            loadsIds.includes(value.id)
          );
          this.copyLoads = filteredDataLoad;
          this.myEvents = this.copyLoads;
          this.refreshResources(selectedDrivers);
          if (shouldUpdate.updateCalendar) {
            this.startSearchDate = this.options.refDate;
            const newRefDate = filteredDataLoad[0].dateTimeLineBgn;
            
            this.navigateToEvent(newRefDate);
            this.options = { refDate: newRefDate };
          } else if (shouldUpdate.goBack) {
            this.navigateToEvent(this.startSearchDate);
            this.options = { refDate: this.startSearchDate };
          }
          this.calendarOptions = {
            ...this.calendarOptions,
            refDate: this.timeLineStart,
          };
        } else {
          this.copyLoads = filteredDataLoad;
          this.myEvents = this.copyLoads;
          this.refreshResources(this.copyDriver);
        }
      }
    });
    this.subscription.add(subscribe);
  }

  navigateToEvent(event: any) {
    if (this.eventcalendar && event) {
      const eventDate = new Date(event);
      this.timeLineStart = eventDate;
      this.eventcalendar.navigate(eventDate);
    }
  }

  // public setMonday() {
  //   let subscribe = this.sharedTimelineService.timelineArray.subscribe(async (count) => {
  //     console.log(count);
      
  //     if (this.isInitialized) {
  //       if (count && count !== 'Current week') {
  //         this.timelineSize = `${count} days`;
  //         let start = new Date();
  //         start.setDate(this.timeLineEnd.getDate() - (count - 1));
  //         this.timeLineStart = start
  //         this.selectedDate = this.timeLineStart;
  //         console.log(start, this.timeLineEnd);
          
  //         this.sharedTimelineService.setStartAndEndTime(start, this.timeLineEnd);
  //         this.calendarOptions.refDate = this.timeLineStart;
  //         this.changeSize(count);
  //         await this.checkCurrentMonth();
  //       } else {
  //         this.timelineSize = `${7} days`;
  //         this.selectedDate = this.timeLineStart;
  //         const monday = this.getMonday();
  //         this.timeLineStart = monday;
  //         let lastDate = new Date();
  //         lastDate.setDate(monday.getDate() + 6);
  //         this.sharedTimelineService.setStartAndEndTime(monday, lastDate);
  //         this.calendarOptions.refDate = this.timeLineStart;
  //         this.changeSize(7);
  //         await this.checkCurrentMonth();
  //       }
  //     }
  //   });
  //   this.subscription.add(subscribe)
  // }

  setMonday() {
    this.subscription.add(
      this.sharedTimelineService.timelineArray.subscribe(
        async (currentCount) => {
          if (!this.isInitialized) return;
  
          const { start, end } = this.getValidatedStartAndEnd();
          if (!start || !end) return;
  
          try {
            if (currentCount && currentCount !== 'Current week') {
              await this.updateTimelineForCustomRange(currentCount, start, end);
            } else {
              await this.updateTimelineForCurrentWeek();
            }
          } catch (error) {
            console.error('Error in setMonday:', error);
          }
          this.applySorting();
        },
        (error) => console.error('Error in timelineArray subscription:', error)
      )
    );
  }
  
  private getValidatedStartAndEnd(): { start?: moment.Moment; end?: moment.Moment } {
    const { start, end } = this.sharedTimelineService.getStartAndEndTime();
    const startMoment = moment(start);
    const endMoment = moment(end);
  
    if (!startMoment.isValid() || !endMoment.isValid()) {
      console.error('Invalid start or end date:', start, end);
      return {};  
    }
    return { start: startMoment, end: endMoment };
  }  

  private async updateTimelineForCustomRange(currentCount: number, start: moment.Moment, end: moment.Moment) {
    this.timelineSize = `${currentCount} days`;
    const isIncreasing = currentCount < end.diff(start, 'days') + 1;
  
    if (isIncreasing) {
      this.timeLineEnd = this.calculateEndDate(this.timeLineStart, currentCount);
    } else {
      this.timeLineStart = this.calculateStartDate(this.timeLineEnd, currentCount);
    }
    this.applyTimelineChanges(this.timeLineStart, this.timeLineEnd, currentCount);
  }
  
  private async updateTimelineForCurrentWeek() {
    const DAYS_IN_WEEK = 7;
    this.timelineSize = `${DAYS_IN_WEEK} days`;
  
    this.timeLineStart = moment(this.getMonday()).startOf('day').toDate();
    this.timeLineEnd = this.calculateEndDate(this.timeLineStart, DAYS_IN_WEEK);
  
    this.applyTimelineChanges(this.timeLineStart, this.timeLineEnd, DAYS_IN_WEEK);
  }
  
  private async applyTimelineChanges(startDate: Date, endDate: Date, dayCount: number) {
    this.selectedDate = startDate;
    this.sharedTimelineService.setStartAndEndTime(startDate, endDate);
    this.updateTimeline(startDate, dayCount);
    await this.checkCurrentMonth();
  }
  
  private calculateStartDate(endDate: Date, daysBefore: number): Date {
    return moment(endDate).subtract(daysBefore - 1, 'days').toDate();
  }
  
  private calculateEndDate(startDate: Date, daysAfter: number): Date {
    return moment(startDate).add(daysAfter - 1, 'days').toDate();
  }
  
  private updateTimeline(startDate: Date, size: number) {
    this.calendarOptions.refDate = startDate;
    this.changeSize(size);
  }
  
  public changeSize(size: number) {
    this.timeLineSize = size;
    this.view = { timeline: { ...view.timeline, size: this.timeLineSize } };
    this.clearTrialLabels();
  }

  public setMonth(): void {
    let subscribe = this.sharedTimelineService.timelineMonthArray.subscribe(async (m) => {
      if (this.isInitialized) {
        if (m === 'month') {
          this.timelineSize = 'month';
          const { firstDay, daysInMonth } = this.getFirstDayOfMonth();
          let lastDate = new Date();
          lastDate.setDate(firstDay.getDate() + daysInMonth - 1);
          this.timeLineEnd = lastDate;
          this.sharedTimelineService.setStartAndEndTime(firstDay, lastDate)
          this.selectedDate = new Date();
          this.timeLineStart = firstDay;
          this.options = {
            refDate: this.timeLineStart,
          };
          this.calendarOptions = {
            ...this.calendarOptions,
            refDate: this.timeLineStart,
          };
          this.changeSize(daysInMonth);
          await this.checkCurrentMonth();
        }
        this.applySorting();
      }
    });
    this.subscription.add(subscribe)
  }

  public getFirstDayOfMonth(): { firstDay: Date; daysInMonth: number } {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    return { firstDay, daysInMonth };
  }

  public async shiftTimeline() {
    let subscribe = this.sharedTimelineService.timelineShiftArray.subscribe(async (count) => {
      if (this.isInitialized) {
        if (count) {
          this.selectedDate.setDate(this.selectedDate.getDate() + count);
          this.timeLineStart.setDate(this.timeLineStart.getDate() + count);
          this.options = {
            refDate: this.timeLineStart,
          };
          this.calendarOptions = {
            ...this.calendarOptions,
            refDate: this.timeLineStart,
          };
          this.view = { timeline: { ...view.timeline, size: this.timeLineSize } };
          let lastDate = new Date(this.timeLineStart);
          lastDate.setDate(lastDate.getDate() + (this.timeLineSize - 1));
          this.timeLineEnd = lastDate
          this.sharedTimelineService.setStartAndEndTime(new Date(this.timeLineStart), lastDate)
          await this.checkCurrentMonth();
          this.clearTrialLabels();
          this.applySorting();
        }
      }
    });
    this.subscription.add(subscribe)
  }

  async checkCurrentMonth() {    
    const yearDiff = this.timeLineStart.getFullYear() - this.currentDate.getFullYear();
    const monthDiff = yearDiff * 12 + (this.timeLineStart.getMonth() - this.currentDate.getMonth());
    
    if (monthDiff > -1 && monthDiff < 1) { 
      return;
    } else {
      this.currentDate = this.timeLineStart;
      await this.refreshTmlnDataResources();
    }
  }  

  createLoad(value: number, selected?: any) {
    this.createLoadWindow = false;
    const dialogRef = this.dialog.open(CreateModalAllComponent, {
      data: {
        partial: false,
        index: value,
        drivers: this.allDrivers,
        carriers: this.allCarriers,
        load: this.newLoad,
        dispatcers: this.allDispatchers,
        startDate: this.createStartDate,
        endDate: this.createEndDate,
      },
    });
    dialogRef.afterClosed().subscribe(async () => {
      await this.refreshTimelineData(this.timeLineStart);
      this.searchByFilteredDriver();
      this.newLoad = undefined;
      this.createStartDate = undefined;
      this.createEndDate = undefined
    });
  }

  editLoad(selectedLoad: any) {
    const dialogRef = this.dialog.open(ViewEditLoadComponent, {
      data: {
        load: selectedLoad.original.id,
        drivers: this.allDrivers,
        carriers: this.allCarriers,
        dispatcers: this.allDispatchers,
      },
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        await this.refreshTimelineData(this.timeLineStart);
        this.searchByFilteredDriver();
      }
    });
  }

  editNote(selectedNote: any) {
    const dialogRef = this.dialog.open(CreateModalNoteComponent, {
      data: {
        note: selectedNote.original,
        editLoad: true,
      },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(async () => {
      // await this.getAllDrivers();
      this.refreshTmlnDataResources();
    });
  }

  editTrailer(resource: any, event: Event) {
    event.stopPropagation();
    this.openEditTrailerPopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      ownerType: this.ownerTypeSource,
      trailerType: this.trailerTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
  }

  editVehicleClick(resource: any, event: Event) {
    event.stopPropagation();
    this.openeditVehiclePopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      makeType: this.makeTypeSource,
      carriers: this.allCarriers
    });
  }

  openeditVehiclePopup(resource: any) {
    const dialogRef = this.dialog.open(EditVehiclePopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.data.data.vehicleUnit + ' ? This action cannot be undone.'
          this.openDeleteDialog(text).then(res => {
            if (res) {
              this.loadService.deleteVehicle(resource.data.vehicleId).subscribe(async () => {
                await this.refreshTmlnDataResources();
                componentInstance.close();
              },
                (error) => {
                  console.log(error);
                });
            }
          })
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          this.updateVehicle(popup.formData, resource.data.vehicleId).then(async () => {
            await this.refreshTmlnDataResources();
            componentInstance.close()
          })
          break;
      }
    });
  }

  openEditTrailerPopup(resource: any) {
    const dialogRef = this.dialog.open(EditTrailerPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.data.data.trailerUnit + ' ? This action cannot be undone.'
          this.openDeleteDialog(text).then(res => {
            if (res) {
              this.loadService.deleteTrailer(resource.data.trailerId).subscribe(async () => {
                await this.refreshTmlnDataResources();
                componentInstance.close();
              }, (error) => {
                console.log(error);
              });
            }
          })
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          this.updateTrailer(popup.formData, resource.data.trailerId).then(async () => {
            await this.refreshTmlnDataResources();
            componentInstance.close()
          })
          break;
      }
    });
  }

  editDriver(resource: any, event: Event) {
    event.stopPropagation();
    this.loadService.getDriverById(resource.id).then((result) => {
      // let data = { ...result, roles: [UserRolesCode.DRIVER], phone: result.phoneNumber, gSearchID: 'masterDriver', coDriverVisible: true };
      result = { ...result, gSearchID: 'masterDriver', roles: [UserRolesCode.DRIVER], coDriverVisible: true };
      this.openEditMainDriverPopup(result);
    });
  }

  editCarrier(resource: any, event: Event) {
    event.stopPropagation();
    this.loadService.getCarrierById(resource.carrierId).then((data) => {
      data.roles = ['Carrier'];
      this.openEditUserPopup(data);
    });
  }

  editDispatcher(resource: any, event: Event) {
    event.stopPropagation();
    this.loadService.getUserById(resource.dispatcherId).then((data) => {
      this.openEditUserPopup(data);
    });
  }

  openEditUserPopup(row: any, mainDriver?: any) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: row }, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate": this.deactivateHandler(row, componentInstance); break;
        case "ok": this.confirmHandler(popup, row, componentInstance, mainDriver); break;
      }
    });
  }

  openEditMainDriverPopup(userData: any) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: userData }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.formData.firstName + ' ' + popup.formData.lastName + ' ? This action cannot be undone.'
          this.driverDeactivateHandler(text, popup.formData).then(res => {
            if (res) componentInstance.close()
          });
          break;
        case "ok": this.driverConfirmHandler(popup.formData, userData.id, componentInstance); break;
        case "editCodriver":
          popup.formData.id = userData.id;
          this.editCoDriverPopup(popup.formData, userData.coDriverId, componentInstance);
          break;

        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance, userData.id); break;
      }
    });
  }

  async editCoDriverPopup(mainDriver: any, codriverID: number, masterPopupInstance: any) {
    let coDriverData = await this.loadService.getDriverById(codriverID);
    const masterDriverData = {
      trailerId: mainDriver.trailerId,
      vehicleId: mainDriver.vehicleId,
      dispatcherId: mainDriver.dispatcherId,
      carrierId: mainDriver.carrierId,
      newTrailerUnit: mainDriver.newTrailerUnit || null,
      newVehicleUnit: mainDriver.newTrailerUnit || null,
      newCarrierName: mainDriver.newTrailerUnit || null,
    }
    coDriverData = { ...coDriverData, gSearchID: 'coDriver', coDriverVisible: false, masterDriverData: masterDriverData, roles: [UserRolesCode.DRIVER] };
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    let data: any = { data: coDriverData };

    componentInstance.subscribe(data, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.formData.firstName + ' ' + popup.formData.lastName + ' ? This action cannot be undone.'
          masterPopupInstance.driverFormComp.row.coDriverId = null;
          componentInstance.close();
          this.driverDeactivateHandler(text, popup.formData).then(res => {
            if (res) {
              masterPopupInstance.driverFormComp.row.coDriverId = null;
              componentInstance.close();
            }
          });
          break;
        case "ok":
          mainDriver.coDriver = popup.formData;
          mainDriver.coDriver.id = codriverID;
          mainDriver.roles = [UserRolesCode.DRIVER];
          this.driverConfirmHandler(mainDriver, mainDriver.id, masterPopupInstance, componentInstance);
          break;
      }
    });
  }

  addCoDriverPopup(coDriverVisible: boolean, masterDriverData: any, masterPopupInstance: any, masterDriverID?: number) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, masterDriverData: masterDriverData, gSearchID: 'coDriver' };
    componentInstance.subscribe({ data: data }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok":
          masterDriverData.coDriver = popup.formData;
          if (masterDriverID) {
            this.updateDriver(masterDriverData, masterDriverID).then(() => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: String.raw`Driver \ CoDriver was updated succesfully!` });
              componentInstance.close();
              masterPopupInstance.close();
            }, err => {
              console.log(err);
            });
          } else {
            this.createDriver(masterDriverData).then(async () => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: String.raw`Driver \ CoDriver was created succesfully!` });
              await this.refreshTmlnDataResources();
              componentInstance.close();
              masterPopupInstance.close();
            }, err => {
              console.log(err);
            });
          }
          break;
      }
    });
  }

  driverConfirmHandler(masterDriverForm: any, driverID: number, masterPopupInstance: any, detailPopupInstance?: any) {
    this.updateDriver(masterDriverForm, driverID).then(async () => {
      if (masterDriverForm.coDriver) this.messageService.add({ severity: 'success', summary: 'Success', detail: String.raw`Driver \ CoDriver was updated succesfully!` });
      else this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Driver was updated succesfully' });
      masterPopupInstance.close();
      if (detailPopupInstance) detailPopupInstance.close();
    });
  }

  driverDeactivateHandler(text: string, row: any): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.openDeleteDialog(text).then(res => {
        if (!res) return;
        this.usersService.deleteDriver(row.id).subscribe(async () => {
          await this.refreshTmlnDataResources();
          resolve(true);
        }, error => {
          reject()
          console.log(error);
        });
      });
    })
  }

  deactivateHandler(row: any, parentDialogRef: any) {
    let deleteName: string = '';
    switch (row.roles[0]) {
      case 'Dispatcher': deleteName = row.firstName + ' ' + row.lastName;
        break;
      case 'Carrier': deleteName = row.name;
        break;
    }
    const text: string = 'Are you sure you want to deactivate ' + deleteName + ' ? This action cannot be undone.'

    this.openDeleteDialog(text).then(res => {
      if (!res) return;

      switch (row.roles[0]) {
        case 'Dispatcher':
          this.usersPageService.deleteUser(row.id).subscribe(() => {
            parentDialogRef.close();
          },
            (error) => {
              console.log(error);
            });
          break;
        case 'Carrier':
          this.loadService.deleteCarrier(row.id).subscribe(() => {
            parentDialogRef.close();
          },
            (error) => {
              console.log(error);
            });
          break;
        case 'Driver':
          this.usersService.deleteDriver(row.id).subscribe(() => {
            parentDialogRef.close();
          },
            (error) => {
              console.log(error);
            });
          break;
      }
    });
  }

  confirmHandler(popup: any, row: any, componentInstance: any, mainDriver?: any) {
    switch (row.roles[0]) {
      case 'Dispatcher':
        this.editUser(popup.formData, row.id).then(async () => {
          await this.refreshTmlnDataResources();
          componentInstance.close()
        })
        break;
      case 'Supervisor':
        this.editUser(popup.formData, row.id).then(async () => {
          await this.refreshTmlnDataResources();
          componentInstance.close()
        })
        break;
      case 'Carrier':
        this.updateCarrier(popup.formData, row.id).then(async () => {
          await this.refreshTmlnDataResources();
          componentInstance.close()
        })
        break;
      case 'Driver':
        mainDriver.coDriver = popup.formData;
        this.updateDriver(popup.formData, row.id).then(async () => {
          await this.refreshTmlnDataResources();
          componentInstance.close()
        })
        break;
    }
  }

  openNotePopup(resource: any) {
    let dialogRef: MatDialogRef<DriverNotePopupComponent> = this.dialog.open(
      DriverNotePopupComponent,
      {
        panelClass: 'custom-container',
        data: resource,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result.data.trim().length === 0) return;
      this.loadService
        .createDriverNote(result.data, resource.id)
        .then(() => {
          this.refreshTimelineData(this.timeLineStart)
        })
        .catch((error) => {
          this.openWarningDialog(error.error.message);
          console.error(error);
        });
    });
  }

  openDeleteDialog(deleteText: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const deletIconStyle = { stroke: '#D92D20', width: '48px', heigth: '48px' };
      const btn1Style = {
        background: 'white',
        color: '#344054',
        border: '1px solid #D0D5DD',
      };
      const btn2Style = {
        background: '#D92D20',
        color: 'white',
        border: '1px solid #D92D20',
      };

      const dialogData: any = {
        title: 'Deactivate',
        text: deleteText,
        button1Text: 'Cancel',
        button2Text: 'Delete',
        icon: 'custom-trash',
        iconStyle: deletIconStyle,
        dsa: true,
      };

      const dialogRef = this.dialogService.openDialog(DialogComponent, {
        props: { ...dialogData, btn1Style, btn2Style },
      });

      dialogRef.subscribe((dialog) => {
        if (!dialog) return;
        switch (dialog.result) {
          case 'button1': resolve(false); break;
          case 'button2': resolve(true); break;
        }
      });
    });
  }

  editUser(data: Array<any>, userID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.usersPageService.editUser(data, userID).subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log(error);
        reject(error);
      }
      );
    });
  }

  updateTarget(data: Array<any>, driverID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.updateTarget(data, driverID).subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log(error);
        reject(error);
      }
      );
    });
  }

  updateCarrier(data: Array<any>, carrierID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.updateCarrier(data, carrierID).subscribe(
        async (res) => {
          await this.refreshTmlnDataResources();
          resolve(res);
        },
        (error) => {
          console.log(error);
          reject(error);
          // this.openWarningDialog(error.error.message);
        }
      );
    });
  }

  updateDriver(data: Array<any>, driverID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.updateDriver(data, driverID).subscribe(
        async (res) => {
          await this.refreshTmlnDataResources();
          resolve(res);
        },
        (error) => {
          console.log(error);
          reject(error);
          // this.openWarningDialog(error.error.message);
        }
      );
    });
  }

  createDriver(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.createDriver(data).subscribe(res => {
        resolve(data);
      }, error => {
        console.log(error);
        reject(error);
      })
    });
  }

  updateTrailer(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.updateTrailer(data, trailerID).subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  updateVehicle(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loadService.updateVehicle(data, trailerID).subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  openWarningDialog(msg: string) {
    const warningIconStyle = {
      stroke: '#FEF0C7',
      width: '48px',
      heigth: '48px',
    };
    const btn2Style = {
      background: '#175CD3',
      color: 'white',
      border: '1px solid #175CD3',
    };

    const dialogData: any = {
      title: 'Warning',
      text: msg,
      button2Text: 'Confirm',
      icon: 'warning-icon',
      iconStyle: warningIconStyle,
    };

    const dialogRef = this.dialogService.openDialog(DialogComponent, {
      props: { ...dialogData, btn2Style },
    });

    dialogRef.subscribe((dialog) => {
      if (!dialog) return;
      switch (dialog.result) {
        case 'button2':
          break;
        default:
          break;
      }
    });
  }

  clearTrialLabels() {
    setTimeout(() => {
      let elements = document.querySelectorAll('.mbsc-schedule-event div');
      elements.forEach((el) => {
        if (el.textContent === 'TRIAL') {
          el.classList.add('display-none');
        }
      });
    }, 200);
  }

  clearTrialLabelsOnCalendar() {
    setTimeout(() => {
      let elements = document.querySelectorAll('.mbsc-popup div ');
      elements.forEach((el) => {
        if (el.textContent === 'TRIAL') {
          el.classList.add('opacity-none');
        }
      });
    }, 50);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectStatus(loadId: any) {
    this.loadService.changeStatusByLoadId(loadId, this.selectedStatus.$name).subscribe(async () => {
      if (this.toolTipImformation.loadId === loadId) {
        this.toolTipImformation.status = this.convertToTitleCase(status)
      }
      this.isDropdownOpen = false;
      await this.refreshTmlnDataResources();
    });

  }

  convertToTitleCase(status: string): string {
    return status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  startClock(): void {
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  updateTime(): void {
    const date = new Date();
    this.browserTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  openDropTotal() {
    this.openDropDownTotal = !this.openDropDownTotal
  }

  checkLoadStatus = (stat: any) => {   
     const status: string = stat.toUpperCase();
    if (status in loadColors) {
      return loadColors[status];
    } else {
      return '#F9FAFB';
    }
  }

  onPopupClose() {
    this.statusDrop?.hide();
  }

  // private updateLoads(newLoad: any) {
  //   let existingLoad: any;

  //   if (newLoad?.load) {
  //     existingLoad = this.loads.find(load => load?.loadId === newLoad.load?.loadId);
  //   } else if (newLoad?.note) {
  //     existingLoad = this.notes.find(note => note?.id === newLoad.note?.id);
  //   }

  //   if (existingLoad) {
  //     Object.assign(existingLoad, newLoad);
  //   } else {
  //     if (newLoad?.type === 'Load') {
  //       this.loads.push(newLoad);
  //       this.copyLoads.push(newLoad);
  //     } else if (newLoad?.type === 'Note') {
  //       this.notes.push(newLoad);
  //     }
  //   }
  // }

  convertNumber(num: number) {
    return Math.round(num)
  }

  private getHoursAndMinutes(selectedLoad: any) {
    let hours = Math.floor(selectedLoad?.duration / 3600);
    let minutes = Math.floor((selectedLoad?.duration % 3600) / 60);

    return hours.toString() + 'h' + minutes.toString() + 'm';
  }

  private fee(fee: Fee): string {
    if (!fee) return '0';
    let feeString = fee.$feeName + '(' + this.feeAction(fee.$addToGross as ActionsFee) + ')' + " " + "$" + fee.$feePrice
    return feeString
  }

  private feeAction(feeAction: ActionsFee): string {
    switch (feeAction) {
      case ActionsFee.PLUS_TO_PRICE:
        return '+';
      case ActionsFee.MINUS_TO_PRICE:
        return '-';
      case ActionsFee.NOT_INCLUDED_IN_PRICE:
        return 'N/I';
      default:
        return '';
    }
  }  

}