import { Copyable } from '../index';

export class Note implements Copyable<Note> {

	private id: number;
	private driverId: number;
	private reason: string;
	private otherReason: string;
	private fullAddress: string;
	private type: string;
	private dateTimeLineBgn: string;
	private dateTimeLineEnd: string;
	private start: string;
	private end: string;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.id = obj && obj.id || 0;
		this.driverId = obj && obj.driverId || 0;
		this.reason = obj && obj.reason || "";
		this.otherReason = obj && obj.otherReason || "";
		this.fullAddress = obj && obj.fullAddress || "";
		this.type = obj && obj.type || "";
		this.dateTimeLineBgn = obj && obj.dateTimeLineBgn || "";
		this.dateTimeLineEnd = obj && obj.dateTimeLineEnd || "";
		this.start = obj && obj.start || "";
		this.end = obj && obj.end || "";
	}

	copy(context: Map<Object, Object>): Note{
		if (context.get(this) == null) {
			let copy : Note = new Note ();
			context.set(this, copy);

			copy.$id = this.$id;
			copy.$driverId = this.$driverId;
			copy.$reason = this.$reason;
			copy.$otherReason = this.$otherReason;
			copy.$fullAddress = this.$fullAddress;
			copy.$type = this.$type;
			copy.$dateTimeLineBgn = this.$dateTimeLineBgn;
			copy.$dateTimeLineEnd = this.$dateTimeLineEnd;
			copy.$start = this.$start;
			copy.$end = this.$end;
		}

		return <Note> context.get(this);
	}

    /**
     * Getter $id
     * @return {number}
     */
	public get $id(): number {
		return this.id;
	}

    /**
     * Getter $driverId
     * @return {number}
     */
	public get $driverId(): number {
		return this.driverId;
	}

    /**
     * Getter $reason
     * @return {string}
     */
	public get $reason(): string {
		return this.reason;
	}

    /**
     * Getter $otherReason
     * @return {string}
     */
	public get $otherReason(): string {
		return this.otherReason;
	}

    /**
     * Getter $fullAddress
     * @return {string}
     */
	public get $fullAddress(): string {
		return this.fullAddress;
	}

    /**
     * Getter $type
     * @return {string}
     */
	public get $type(): string {
		return this.type;
	}

    /**
     * Getter $dateTimeLineBgn
     * @return {string}
     */
	public get $dateTimeLineBgn(): string {
		return this.dateTimeLineBgn;
	}

    /**
     * Getter $dateTimeLineEnd
     * @return {string}
     */
	public get $dateTimeLineEnd(): string {
		return this.dateTimeLineEnd;
	}

    /**
     * Getter $start
     * @return {string}
     */
	public get $start(): string {
		return this.start;
	}

    /**
     * Getter $end
     * @return {string}
     */
	public get $end(): string {
		return this.end;
	}

    /**
     * Setter $id
     * @param {number} value
     */
	public set $id(value: number) {
		this.id = value;
	}

    /**
     * Setter $driverId
     * @param {number} value
     */
	public set $driverId(value: number) {
		this.driverId = value;
	}

    /**
     * Setter $reason
     * @param {string} value
     */
	public set $reason(value: string) {
		this.reason = value;
	}

    /**
     * Setter $otherReason
     * @param {string} value
     */
	public set $otherReason(value: string) {
		this.otherReason = value;
	}

    /**
     * Setter $fullAddress
     * @param {string} value
     */
	public set $fullAddress(value: string) {
		this.fullAddress = value;
	}

    /**
     * Setter $type
     * @param {string} value
     */
	public set $type(value: string) {
		this.type = value;
	}

    /**
     * Setter $dateTimeLineBgn
     * @param {string} value
     */
	public set $dateTimeLineBgn(value: string) {
		this.dateTimeLineBgn = value;
	}

    /**
     * Setter $dateTimeLineEnd
     * @param {string} value
     */
	public set $dateTimeLineEnd(value: string) {
		this.dateTimeLineEnd = value;
	}

    /**
     * Setter $start
     * @param {string} value
     */
	public set $start(value: string) {
		this.start = value;
	}

    /**
     * Setter $end
     * @param {string} value
     */
	public set $end(value: string) {
		this.end = value;
	}
	
}

