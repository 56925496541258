import { Component, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { Driver, Load } from '../../../../../_models';
import { DataService } from '../../../../../services/reference-data/reference-data.service';
import { SharedService } from '../../../../../services/sharedServices/shared.service';
import { loadColors } from '../types/load-status-color';
import { Carrier } from '../../../../../_models/object/carrier';
import { SharedTimeService } from '../../../../../services/sharedServices/shared-time.service';
import moment from 'moment';
import { trigger, transition, style, animate } from '@angular/animations';
import { SettingsComponent } from '../../../../../interfaces/settings-component';
import { StorageService } from '../../../../../services/storage/storage.service';
import { Dialog } from 'primeng/dialog';
import { Button } from 'primeng/button';

@Component({
  selector: 'app-time-line-header-prime',
  templateUrl: './time-line-header-prime.component.html',
  styleUrl: './time-line-header-prime.component.scss',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]

})
export class TimeLineHeaderPrimeComponent {
  @ViewChild('settingsPopup') settingsPopup: Dialog | undefined;
  @ViewChild('openSetPopBtn') openSetPopBtn!: Button;

  @Output() refreshResource = new EventEmitter<any>();
  @Output() settingsConfirmed = new EventEmitter<any>();

  private destroy$ = new Subject<void>(); 
  private itemsSubscription: Subscription = new Subscription;
  private searchInputSubject = new Subject<string>();

  public selectedDriver: Array<Driver> = new Array<Driver>();
  public selectedCarrier: Array<Carrier> = new Array<Carrier>();
  public selectedDispatcher: any;
  public selectedStatus: any;
  private allDrivers: Array<Driver> = new Array<Driver>();
  private allLoads: Array<Load> = new Array<Load>();
  private filteredLoad: Array<Load> = new Array<Load>();
  private filteredDriver: Array<Driver> = new Array<Driver>();
  public status: Array<any> = new Array<any>();
  public carriers: Array<Carrier> = new Array<Carrier>();
  public drivers: Array<Driver> = new Array<Driver>();
  public dispatchers: Array<any> = new Array<any>();
  public searchValue!: any;
  
  public tempSettingsData: SettingsComponent = {
    showLoadId: true,
    showPricePerLoad: true,
    showMiles: true,
    showRatePerMiles: true,
    hideTotalBy: false,
    hideUnassignedLoads: false,
    showTruck: false,
    showTrailer: false,
    // hideOwned: false,
  };

  private settingsData!: SettingsComponent;
  public setttingsPopupVisible: boolean = false;
  private start = moment();
  private end = moment();

  settingsOptions = [
    { key: 'showLoadId', label: 'Show Load ID', isOnTop: true },
    { key: 'showPricePerLoad', label: 'Show Price per Load', isOnTop: true },
    { key: 'showMiles', label: 'Show Miles', isOnTop: true },
    { key: 'showRatePerMiles', label: 'Show Rate per Miles', isOnTop: true },
    { key: 'hideTotalBy', label: 'Hide Total by', isOnTop: true },
    { key: 'hideUnassignedLoads', label: 'Hide Unassigned Loads', isOnTop: true },
    { key: 'showTruck', label: 'Show Truck' },
    { key: 'showTrailer', label: 'Show Trailer' },
    { key: 'showTruck', label: 'Hide Company' },
    { key: 'showTrailer', label: 'Hide Dispatcher' },
  ];

  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    private dataService: DataService,
    private sharedTimelineService: SharedTimeService,
    private storageService: StorageService,
  ) { }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (
      this.settingsPopup && 
      this.setttingsPopupVisible && 
      !this.settingsPopup?.el.nativeElement.firstChild.children[0].contains(event.target) && 
      !this.openSetPopBtn?.el.nativeElement.contains(event.target)) 
    {
      this.setttingsPopupVisible = false;
    }
  }

  ngOnInit() {
    this.initHeader();
  }

  initHeader() {
    this.sharedService.dataArray.pipe(takeUntil(this.destroy$)).subscribe((driver) => {
      this.allDrivers = driver;
      this.drivers = driver.map((driver: Driver) => new Driver(driver));
      this.combinedFilter();
    });

    this.sharedService.dataArrayCarrier$.pipe(takeUntil(this.destroy$)).subscribe((carriers) => {
      this.carriers = carriers;
    });

    this.sharedService.dataArrayDispatcher$.pipe(takeUntil(this.destroy$)).subscribe((disp) => {
      this.dispatchers = [
        ...new Set(disp.map((disp) => JSON.stringify({ id: disp.id, name: disp.fullName })) ),
      ];
      this.dispatchers = Array.from(this.dispatchers).map(disp => JSON.parse(disp));
    });

    this.searchInputSubject
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchTerm) => {
        this.searchValue = searchTerm;
          this.filterLoad({updateCalendar: searchTerm !== '', goBack: searchTerm === ''}); 
      });

    this.startItemsObserver();
    this.getStorage();
  }

  onSearchInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(inputValue);
  }

  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe(items => {
      items.map(item => {
        switch (item.type) {
          case 'LoadStatusLoad': this.status.push(item); break;
        }
      });
    });
  }

  checkLoadStatus(status: any) {
    if (status.code in loadColors) {
      return loadColors[status.code];
    } else {
      return '#F9FAFB';
    }
  }

  getLoadStatus = (status: any): number => {
    const { start, end } = this.sharedTimelineService.getStartAndEndTime();
    const filteredLoadsByTmlnDate = this.filterLoadsByTimeRange(this.sharedService.getLoad(), start, end);
    return filteredLoadsByTmlnDate.filter((f: any) => f?.status === status.code).length
  }

  removeStatus(item: any, event: Event) {
    event.stopPropagation();
    this.selectedStatus = this.selectedStatus.filter((s:any) => s !== item);
    this.toggleStatus();
  }

  toggleDispatcher = () => {
    this.filterDrivers();
    this.combinedFilter();
  }

  toggleCarrier = () => {
    this.filterDrivers();
    this.combinedFilter();
  }

  toggleStatus = () => {
    this.filterStatus();
  }

  toggleDriver = () => {
    this.combinedFilter();
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.cleanFilters();
  }

  filterDrivers() {
    if (!this.allDrivers) {
      return;
    }
  
    let filteredDrivers = new Set<Driver>();
  
    if (this.selectedCarrier && this.selectedCarrier.length > 0) {
      this.allDrivers.forEach((driver) => {
        if (this.selectedCarrier.some((carrier) => driver.$carrierId === carrier.$id)) {
          filteredDrivers.add(driver);
        }
      });
    }
  
    if (this.selectedDispatcher && this.selectedDispatcher.length > 0) {
      this.allDrivers.forEach((driver) => {
        if (this.selectedDispatcher.some((dispatcher: any) => driver.$dispatcherId === dispatcher.id)) {
          filteredDrivers.add(driver);
        }
      });
    }
  
    if (
      (!this.selectedCarrier || this.selectedCarrier.length === 0) &&
      (!this.selectedDispatcher || this.selectedDispatcher.length === 0)
    ) {
      this.drivers = [...this.allDrivers];
    } else {
      this.drivers = Array.from(filteredDrivers);
    }
  }
  
  combinedFilter() {
    this.allDrivers = this.sharedService.getDriver();
  
    let driverIdsInLoads = new Set(
      this.filteredLoad.map((load) => load.$driverId)
    );
  
    let filteredDrivers = new Set<Driver>();
  
    // Unificăm rezultatele după Carrier și Dispatcher
    if (this.selectedCarrier && this.selectedCarrier.length > 0) {
      this.allDrivers.forEach((driver) => {
        if (this.selectedCarrier.some((carrier) => driver.$carrierId === carrier.$id)) {
          filteredDrivers.add(driver);
        }
      });
    }
  
    if (this.selectedDispatcher && this.selectedDispatcher.length > 0) {
      this.allDrivers.forEach((driver) => {
        if (this.selectedDispatcher.some((dispatcher:any) => driver.$dispatcherId === dispatcher.id)) {
          filteredDrivers.add(driver);
        }
      });
    }
  
    // Dacă nu există Carrier sau Dispatcher selectat, luăm toți driverii
    let unifiedDrivers = filteredDrivers.size > 0 ? Array.from(filteredDrivers) : [...this.allDrivers];
  
    // Filtrăm după Driver din setul unificat Carrier + Dispatcher
    let finalFilteredDrivers = new Set<Driver>();
  
    if (this.selectedDriver && this.selectedDriver.length > 0) {
      unifiedDrivers.forEach((driver) => {
        if (this.selectedDriver.some((d) => driver.$id === d.$id)) {
          finalFilteredDrivers.add(driver);
        }
      });
    } else {
      finalFilteredDrivers = new Set(unifiedDrivers);
    }
  
    // Filtrăm după SearchValue
    if (this.searchValue) {
      finalFilteredDrivers = new Set(
        Array.from(finalFilteredDrivers).filter((driver) => driverIdsInLoads.has(driver.$id))
      );
    }
  
    // Dacă nu există niciun filtru activ, returnăm toți driverii
    if (
      (!this.selectedCarrier || this.selectedCarrier.length === 0) &&
      (!this.selectedDispatcher || this.selectedDispatcher.length === 0) &&
      (!this.selectedDriver || this.selectedDriver.length === 0) &&
      (!this.searchValue)
    ) {
      this.filteredDriver = [...this.allDrivers];
    } else {
      this.filteredDriver = Array.from(finalFilteredDrivers);
    }
  
    this.sharedService.setFilterDriver(this.filteredDriver);
  }    
  
  // filterDrivers() {
  //   if (!this.allDrivers) {
  //     return;
  //   }
  
  //   this.drivers = this.allDrivers.filter((driver: Driver) => {
  //     const matchesCarrier =
  //       this.selectedCarrier && this.selectedCarrier.length > 0
  //         ? this.selectedCarrier.some(
  //             (carrier: Carrier) => driver.$carrierId === carrier.$id
  //           )
  //         : true;
  
  //     const matchesDispatcher =
  //       this.selectedDispatcher && this.selectedDispatcher.length > 0
  //         ? this.selectedDispatcher.some(
  //             (dispatcher: Dispatcher) => driver.$dispatcherId === dispatcher.$id
  //           )
  //         : true;
  
  //     return matchesCarrier && matchesDispatcher;
  //   });
  // }    

  // combinedFilter() {
  //   this.allDrivers = this.sharedService.getDriver();

  //   let driverIdsInLoads = new Set(
  //     this.filteredLoad.map((load) => load.$driverId)
  //   );

  //   this.filteredDriver = this.allDrivers.filter((driver: Driver) => {
  //     const matchesSearchValue = this.searchValue
  //       ? driverIdsInLoads.has(driver.$id)
  //       : true;
  //     const matchesCarrier =
  //       this.selectedCarrier && this.selectedCarrier.length > 0
  //         ? this.selectedCarrier.some((carrier: Carrier) => driver.$carrierId === carrier.$id)
  //         : true;
  //     const matchesDispatcher =
  //       this.selectedDispatcher && this.selectedDispatcher.length > 0
  //         ? this.selectedDispatcher.some((dispatcher: any) => driver.$dispatcherId === dispatcher.id)
  //         : true;
  //     const matchesDriver =
  //       this.selectedDriver && this.selectedDriver.length > 0
  //         ? this.selectedDriver.some((d: Driver) => driver.$id === d.$id)
  //         : true;

  //     return (
  //       matchesCarrier &&
  //       matchesDriver &&
  //       matchesDispatcher &&
  //       matchesSearchValue
  //     );
  //   });
  //   if (
  //     (!this.selectedCarrier || this.selectedCarrier.length === 0) &&
  //     (!this.selectedDispatcher || this.selectedDispatcher.length === 0) &&
  //     (!this.selectedDriver || this.selectedDriver.length === 0) &&
  //     (!this.selectedStatus || this.selectedStatus.length === 0)
  //   ) {
  //     this.filteredDriver = [...this.allDrivers];
  //   }
  //   this.sharedService.setFilterDriver(this.filteredDriver);
  // }

  filterLoad(settings: any) {
    this.allLoads = this.sharedService.getLoad();
    this.filteredLoad = this.allLoads.filter((load: Load) => {
        const matchesSearchValue = this.searchValue
          ? load.$customerLoadId.includes(this.searchValue)
          : true;
        return matchesSearchValue;
    });

    if (!this.searchValue) {
        this.filteredLoad = [...this.allLoads];
    } 
    
    this.sharedService.setShouldUpdateCalendar(settings);
    this.sharedService.setFilteredLoad(this.filteredLoad);
  }

  filterStatus() {
    this.allLoads = this.sharedService.getLoad();

    if (this.selectedStatus && this.selectedStatus.length !== 0) {
      this.filteredLoad = this.allLoads.filter((load: Load) => 
        this.selectedStatus.some((status:any) => load.$status.toLowerCase() === status.code.toLowerCase())
      );
      this.sharedService.setShouldUpdateCalendar({updateCalendar: false, goBack: false});
    } else {
      this.filteredLoad = [...this.allLoads];
    }
    this.sharedService.setFilteredLoad(this.filteredLoad); 
  }

  filterLoadsByTimeRange(loads: Load[], start: string, end: string): any[] {
    const startMoment = moment(start).add(1, 'days');
    const endMoment = moment(end).add(1, 'days');
  
    return loads.filter((load) => {
      const loadStart = moment(load.$dateTimeLineBgn);
      const loadEnd = moment(load.$dateTimeLineEnd);
  
      return (
        loadStart.isBetween(startMoment, endMoment, undefined, '[]') ||
        loadEnd.isBetween(startMoment, endMoment, undefined, '[]') ||
        (loadStart.isBefore(startMoment) && loadEnd.isAfter(endMoment)) // pentru loaduri care start/end sunt in afara la range,dar range se include in load interval
      );
    });
  }    

  cleanFilters() {
    if (
      (!this.selectedCarrier || this.selectedCarrier.length === 0) &&
      (!this.selectedDispatcher || this.selectedDispatcher.length === 0) &&
      (!this.selectedDriver || this.selectedDriver.length === 0) &&
      (!this.selectedStatus || this.selectedStatus.length === 0) &&
      (!this.searchValue)
    ) {
      return;
    } else {
      this.selectedCarrier = [];
      this.searchValue = '';
      this.searchInputSubject.next('');
      this.selectedDispatcher = [];
      this.selectedDriver = [];
      this.selectedStatus = [];
      this.drivers = this.allDrivers.map((driver: Driver) => new Driver(driver));
      
      this.sharedService.setFilteredLoad(this.allLoads);
      this.sharedService.setFilterDriver(this.allDrivers);
    }
  }

  refreshTimeline() {
    this.refreshResource.emit();
  }

  toggleSettings(settingKey: keyof SettingsComponent): void {
    if (settingKey in this.tempSettingsData) {
      this.tempSettingsData[settingKey] = !this.tempSettingsData[settingKey];
      this.tempSettingsData = { ...this.tempSettingsData };
      this.settingsConfirmed.emit(this.tempSettingsData);
      this.storageService.updateStorageField('timeLineSettings', this.tempSettingsData);
    } else {
      console.warn(`Unknown setting: ${settingKey}`);
    }
  }  

  get topSettingsOptions() {
    return this.settingsOptions.filter(option => option.isOnTop);
  }
  
  get bottomSettingsOptions() {
    return this.settingsOptions.filter(option => !option.isOnTop);
  }
  
  openSettingModal() {
    this.setttingsPopupVisible = true;
  }

  closeSettingModal() {
    this.setttingsPopupVisible = false;
  }
  
  getStorage() {
    let storage = this.storageService.getStorage();
    if (!storage) return;
    if (storage?.start !== '' && storage?.end !== '') {
      this.start = storage?.start
      this.end = storage?.end
      this.sharedTimelineService.setStartAndEndTime(
        storage?.start,
        storage?.end
      );
      this.sharedTimelineService.startDateTimeString.subscribe(start => {
        this.start = start;
      });
      this.sharedTimelineService.endtDateTimeString.subscribe(end => {
        this.end = end;
      });
    }

    this.tempSettingsData = storage?.timeLineSettings
    this.settingsConfirmed.emit(this.tempSettingsData);
  }
}
