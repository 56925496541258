<div class="timeline-prime-container">

    <div class="datepicker-container">
        <p-calendar 
            [showIcon]="true" 
            [iconDisplay]="'input'" 
            [(ngModel)]="dateRange" 
            selectionMode="range" 
            [readonlyInput]="true" 
            (onSelect)="onSelectCalendar()"
        />
    </div>

    <div class="date-btn-container">
        <div class="timeline-equalaizer">
            <p-button label="1 Week" [outlined]="true" severity="secondary" styleClass="equalaizer-btn" (onClick)="shiftTimeline(-7)">
                <ng-template pTemplate="icon">
                    <svg-icon src="assets/svg/1-week-arrow-previous.svg"></svg-icon>
                </ng-template>
            </p-button>

            <p-button label="1 Day" [outlined]="true" severity="secondary" styleClass="equalaizer-btn" (onClick)="shiftTimeline(-1)">
                <ng-template pTemplate="icon">
                    <svg-icon src="assets/svg/1-day-arrow-previous.svg"></svg-icon>
                </ng-template>
            </p-button>

            <p-selectButton 
                severity="secondary"
                optionLabel="label"
                optionValue="value"
                [unselectable]="true"
                [options]="selectButtonOptions" 
                [(ngModel)]="selectedOption"
                (click)="selectOnChange()"
            ></p-selectButton>

            <p-button label="1 Day" [outlined]="true" severity="secondary" styleClass="equalaizer-btn" (onClick)="shiftTimeline(1)">
                <ng-template pTemplate="icon">
                    <svg-icon src="assets/svg/1-day-arrow-next.svg"></svg-icon>
                </ng-template>
            </p-button>

            <p-button label="1 Week" [outlined]="true" severity="secondary" styleClass="equalaizer-btn" (onClick)="shiftTimeline(7)">
                <ng-template pTemplate="icon">
                    <svg-icon src="assets/svg/double-chevron-rigth.svg"></svg-icon>
                </ng-template>
            </p-button>
        </div>

        <p-button class="create-btn" (onClick)="menu.toggle($event)" label="Create"></p-button> 
        <p-tieredMenu #menu [model]="menuItems" [popup]="true" appendTo="body" styleClass="create-list"></p-tieredMenu>
    </div>
</div>