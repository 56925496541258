<div class="tmln-header-container">
    <div class="header-left-container">
        <p-iconField iconPosition="right" (click)="searchInput.focus()">
            <p-floatLabel>    
                <p-inputIcon styleClass="pi pi-search" />
                <input 
                    #searchInput
                    type="text" 
                    id="search-label"
                    pInputText 
                    [(ngModel)]="searchValue" 
                    (input)="onSearchInput($event)"
                >
                <label for="search-label">Search</label>
            </p-floatLabel>
        </p-iconField>

        <p-floatLabel>            
            <p-multiSelect 
                inputId="carrier-float-label" 
                optionLabel="name" 
                [options]="carriers" 
                [(ngModel)]="selectedCarrier" 
                [selectedItemsLabel]="'{0} Carriers selected'"
                [maxSelectedLabels]="2"
                (onChange)="toggleCarrier()" 
            />
            <label for="carrier-float-label">Carrier</label>
        </p-floatLabel>

        <p-floatLabel>
            <p-multiSelect 
                inputId="dispatcher-float-label" 
                optionLabel="name" 
                [options]="dispatchers" 
                [(ngModel)]="selectedDispatcher" 
                [selectedItemsLabel]="'{0} Dispatchers selected'"
                [maxSelectedLabels]="2"
                (onChange)="toggleDispatcher()"
            />
            <label for="dispatcher-float-label">Dispatcher</label>
        </p-floatLabel>

        <p-floatLabel>
            <p-multiSelect 
                inputId="driver-float-label" 
                optionLabel="driverFullName" 
                [options]="drivers" 
                [(ngModel)]="selectedDriver" 
                [selectedItemsLabel]="'{0} Driver selected'"
                [maxSelectedLabels]="2"
                (onChange)="toggleDriver()" 
            />
            <label for="driver-float-label">Driver</label>
        </p-floatLabel>

        <p-floatLabel>
            <p-multiSelect 
                inputId="status-float-label" 
                optionLabel="name" 
                [options]="status" 
                [(ngModel)]="selectedStatus" 
                (onChange)="toggleStatus()" 
                [maxSelectedLabels]="2"
            >
                <ng-template let-selected pTemplate="selectedItems">
                    @if(selected) {
                        <div class="custom-chips">
                            <ng-container *ngFor="let item of selected.slice(0, 1)">
                                <div class="custom-chip" [@slideInOut]>
                                    <span class="chip-content">{{ item.name }}</span>
                                    <i class="pi pi-times-circle chip-remove" (click)="removeStatus(item, $event)"></i>
                                </div>
                            </ng-container>
                            <span *ngIf="selected.length > 1" class="selected-count">
                                {{ selected.length }} Status selected
                            </span>
                        </div>
                    }
                </ng-template>
                
                <ng-template let-stat pTemplate="item">
                    <div class="custom-multiselect-item">
                        {{ stat.name }}
                        <span class="status-badge" [style.color]="checkLoadStatus(stat)">
                        {{ getLoadStatus(stat) }}
                        </span>
                    </div>
                </ng-template>
            </p-multiSelect>
            <label for="status-float-label">Status</label>
        </p-floatLabel>
    </div>

    <div class="header-right-container">
        <p-button [outlined]="true" severity="secondary" (onClick)="cleanFilters()">
            <svg-icon src="assets/svg/metla-new.svg" alt="bin"></svg-icon>
        </p-button>
        <p-button [outlined]="true" severity="secondary" appDebounceClick (debounceClick)="refreshTimeline()">
            <svg-icon src="assets/svg/refresh-new.svg" alt="bin"></svg-icon>
        </p-button>
        <p-button #openSetPopBtn [outlined]="true" severity="secondary" (onClick)="openSettingModal()">
            <svg-icon src="assets/svg/cog-new.svg" alt="bin"></svg-icon>
        </p-button>
    </div>

    <p-dialog 
      #settingsPopup
      [(visible)]="setttingsPopupVisible" 
      [modal]="true"
      [position]="'right'"
      [resizable]="false"
      styleClass="settings-popup"
    >
      <ng-template pTemplate="header">
        <div class="dialog-header">
          <div class="container-icon">
            <svg-icon src="assets/svg/settings-02-stroke-rounded-2-img.svg"></svg-icon>
          </div>
          <div>
            <strong class="timeline-strong">Timeline Settings</strong>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="hr-divider"></div>
        <div class="settings-inputs">
            <div *ngFor="let option of topSettingsOptions" class="toggle-containder">
                <input 
                    (click)="toggleSettings(option.key)" 
                    class="apple-switch" 
                    type="checkbox" 
                    [(ngModel)]="tempSettingsData[option.key]"
                >
                <span (click)="toggleSettings(option.key)" class="toggle-text">
                    {{ option.label }}
                </span>
            </div>
            
              <svg-icon src="assets/svg/Divider-wrap.svg"> </svg-icon>
            
            <div *ngFor="let option of bottomSettingsOptions" class="toggle-containder">
                <input 
                    (click)="toggleSettings(option.key)" 
                    class="apple-switch" 
                    type="checkbox" 
                    [(ngModel)]="tempSettingsData[option.key]"
                >
                <span (click)="toggleSettings(option.key)" class="toggle-text">
                    {{ option.label }}
                </span>
            </div>
        </div>
      </ng-template>
  </p-dialog>

</div>
