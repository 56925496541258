import { Component, EventEmitter, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { SharedTimeService } from '../../../../../services/sharedServices/shared-time.service';
import { StorageService, StorageTempate } from '../../../../../services/storage/storage.service';
import moment from 'moment';

@Component({
  selector: 'app-time-line-filter-prime',
  templateUrl: './time-line-filter-prime.component.html',
  styleUrl: './time-line-filter-prime.component.scss'
})
export class TimeLineFilterPrimeComponent {
  @Output() selectedTab = new EventEmitter<number>();

  constructor(
    private sharedTimeService: SharedTimeService,
    private storageService: StorageService
  ) { }

  public dateRange: Date[] | undefined;
  public selectedOption: any;
  public start = moment();
  public end = moment();

  public selectButtonOptions = [
    { label: '7 Days', value: 7 },
    { label: '10 Days', value: 10 },
    { label: '14 Days', value: 14 },
    { label: 'Month', value: 30 },
    { label: 'Current Week', value: 'Current week' },
    // { label: 'Month', value: 'month' },
  ];

  public menuItems = [
    {
      label: 'Create Load',
      disabled: false,
      command: () => this.openCreateModal(0)
    },
    {
      label: 'Create Tonu',
      disabled: false,
      command: () => this.openCreateModal(1)
    },
    {
      label: 'Create Note',
      disabled: false,
      command: () => this.openCreateModal(2)
    }
  ];

  openCreateModal(index: number) {
    this.selectedTab.emit(index);
  }

  ngOnInit() {
    this.getStorage();
  }

  getStorage() {
    const storage: StorageTempate | null = this.storageService.getStorage();
    if (!storage) return;
  
    if (storage.start && storage.end) {
      this.start = moment(storage.start).startOf('day');
      this.end = moment(storage.end).startOf('day');
      this.sharedTimeService.setStartAndEndTime(this.start, this.end);
    }

    combineLatest([
      this.sharedTimeService.startDateTimeString,
      this.sharedTimeService.endtDateTimeString
    ]).subscribe(([start, end]) => {
      this.start = moment(start).startOf('day');
      this.end = moment(end).startOf('day');
    
      this.dateRange = [this.start.toDate(), this.end.toDate()];
    });
    
    this.dateRange = [this.start.toDate(), this.end.toDate()];

    if(!storage.customRangeCalendar) {
      if(storage.currWeek) {
        this.selectedOption = 'Current week';
      } else {
        const diffDays = this.end.diff(this.start, 'days') + 1;
        let obj = this.selectButtonOptions.find(option => option.value === diffDays) || (diffDays > 14 ? { value: 'month' } : null);
        this.selectedOption = obj ? obj.value : null;      
      }
    }
  }

  onSelectCalendar() {
    if (this.isRangeCompleted()) {
      const [start, end] = this.dateRange!;
      
      this.start = moment(start);
      this.storageService.updateStorageField('start', this.start.toISOString());
  
      this.end = moment(end);
      this.storageService.updateStorageField('end', this.end.toISOString());
      this.storageService.updateStorageField('customRangeCalendar', true);
  
      this.sharedTimeService.setStartAndEndTime(this.start, this.end);
      this.selectedOption = null;
    }
  }

  isRangeCompleted(): boolean {
    return this.dateRange && this.dateRange.every(date => date) || false;
  }

  setMonday(value: number | string) {
    this.sharedTimeService.setTimeForTimeline(value);
  }

  shiftTimeline(value: number) {
    this.sharedTimeService.setShiftTime(value);
  }

  selectOnChange() {
    switch (this.selectedOption) {
      case 'Current week':
        this.setMonday('Current week');
        break;
      case 'month':
        this.setMonth();
        break;
      default:
        this.setMonday(this.selectedOption);
    }    
    this.storageService.updateStorageField('customRangeCalendar', false);
  }

  setMonth() {
    // this.sharedTimeService.setTimeForMonth('month');
  }
}
