import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadsService } from '../../../services/loads/loads.service';
import { Country as SelectedCountry } from '@angular-material-extensions/select-country';
import { State }  from 'country-state-city';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { Item } from '../../../interfaces/item';
import { PopUpSubscriptBase } from '../../../services/popup-service/popup_subscribe_services';
import { Subject } from 'rxjs';
import { LoaderService } from '../../../services/loader/loader.service';

@Component({
  selector: 'app-edit-vehicle-popup',
  templateUrl: './edit-vehicle-popup.component.html',
  styleUrl: './edit-vehicle-popup.component.scss'
})
export class EditVehiclePopupComponent extends PopUpSubscriptBase implements OnInit, OnDestroy {

  onConfirm = new EventEmitter();
  onDeactivate = new EventEmitter();

  public vehicleForm: FormGroup;
  public states: Array<any> = new Array<any>();
  public data: any;

  constructor(
    private fb: FormBuilder,
    private loadService: LoadsService,
    private dialogRef: MatDialogRef<EditVehiclePopupComponent>,
  ) {
    super(new Subject<any>());
      this.vehicleForm = this.fb.group({
        carrierId: ['', Validators.required],
        vehicleUnit: ['', Validators.required],
        vin: ['', Validators.required],
        make: ['', Validators.required],
        model: ['', Validators.required],
        year: ['', Validators.required],
        fuelCode: ['', Validators.required],
        country: [{alpha2Code: "US"}],
        stateProvinceCode: [null],
        plateNumber: [''],
    });
  }
  public carriers: Array<any> = new Array<any>();
  public yearsArr: Array<any> = new Array<any>();
  public modelArr: Array<Item> = new Array<Item>();
  public fuelCodeArr: Array<Item> = new Array<Item>();;
  public vehicleData: any = {};
  
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  protected override showAPopUp(data: any): void {
    this.data = data.data;    
  }

  ngOnInit(): void {
    this.initializePopupData();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  };

  initializePopupData() {
    if(!this.data.createVehicle) {
      this.loadService.getVehicleById(this.data.data.vehicleId).then(data=> {
        this.vehicleData = data;
        this.setFormData();
      }, (err) => {
        this.setTouchedForm();
      });
    } else {
      this.setTouchedForm();
    }

    this.modelArr = this.data.makeType;
    this.fuelCodeArr = this.data.fuelType;
    this.carriers = this.data.carriers;

    const currYear = new Date().getFullYear();
    for (let i = currYear - 50; i <= currYear; i++) {
      const element = {value: i.toString(), viewValue: i.toString()};
      this.yearsArr.push(element);
    }
    this.vehicleForm.controls['country'].valueChanges.subscribe((country)=>this.stateProvinceCodeHandler(country));
  }

  setTouchedForm() {
    this.vehicleForm.markAllAsTouched();    
    this.vehicleForm.markAsPristine(); 
  }

  setFormData() {
    let selectedCountry: SelectedCountry = {alpha2Code: this.vehicleData.countryCode};
    this.vehicleForm.setValue({
      carrierId: this.vehicleData.carrierId,
      vehicleUnit: this.vehicleData.vehicleUnit,
      vin: this.vehicleData.vin,
      make: this.vehicleData.make,
      model: this.vehicleData.model,
      year: this.vehicleData.year,
      fuelCode: this.vehicleData.fuelCode,
      country: selectedCountry.alpha2Code.length > 0 ? selectedCountry : {alpha2Code: "US"},
      stateProvinceCode: this.vehicleData.stateProvinceCode,
      plateNumber: this.vehicleData.plateNumber,
    });
    this.setTouchedForm();
  }

  stateProvinceCodeHandler(country: any) {
    if(country && country.alpha2Code) this.states = this.getStatesByCountry(country);
    else this.states.length = 0;
    // if(this.states.length === 0) {
    //   this.vehicleForm.get('stateProvinceCode')?.clearValidators();
    //   this.vehicleForm.get('stateProvinceCode')?.patchValue(undefined);
    // }
    // else this.vehicleForm.get('stateProvinceCode')?.setValidators([Validators.required]);
    // this.vehicleForm.get('stateProvinceCode')?.updateValueAndValidity();
  }
  
  deactivateClick() {
    this._result.next({type:"deactivate", data: this.data});
  }
  
  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.dialogRef.close();
  }

  okBtnClick() {
    this._result.next({type:"ok", formData: this.vehicleForm.value});
  }

  getStatesByCountry(country: SelectedCountry) {
    return State.getStatesOfCountry(country.alpha2Code);
  }

  isStateLabelVisible(): boolean {
    return !this.vehicleForm.get('stateProvinceCode')?.value || !this.states.some(s=>s.isoCode === this.vehicleForm.get('stateProvinceCode')?.value);
  }

  isCarrierLabelVisible(): boolean {
    return !this.vehicleForm.get('carrierId')?.value || !this.carriers.some(s=>s.id === this.vehicleForm.get('carrierId')?.value);
  }

  isYearLabelVisible(): boolean {
    return !this.vehicleForm.get('year')?.value || !this.yearsArr.some(s=>s.value === this.vehicleForm.get('year')?.value);
  }

  isMakeTypeLabelVisible(): boolean {
    return !this.vehicleForm.get('make')?.value || !this.modelArr.some(s=>s.code === this.vehicleForm.get('make')?.value);
  }

  isFuelLabelVisible(): boolean {
    return !this.vehicleForm.get('fuelCode')?.value || !this.fuelCodeArr.some(s=>s.code === this.vehicleForm.get('fuelCode')?.value);
  }
}
