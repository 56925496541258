import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadsService } from '../../../services/loads/loads.service';
import { Country as SelectedCountry } from '@angular-material-extensions/select-country';
import { State }  from 'country-state-city';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { Item } from '../../../interfaces/item';
import { PopUpSubscriptBase } from '../../../services/popup-service/popup_subscribe_services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-trailer-popup',
  templateUrl: './edit-trailer-popup.component.html',
  styleUrl: './edit-trailer-popup.component.scss'
})
export class EditTrailerPopupComponent extends PopUpSubscriptBase implements OnInit, OnDestroy {

  public trailerForm: FormGroup;
  public states: Array<any> = new Array<any>();

  constructor(
    private fb: FormBuilder,
    private loadService: LoadsService,
    private dialogRef: MatDialogRef<EditTrailerPopupComponent>,
  ) {
    super(new Subject<any>());
    this.trailerForm = this.fb.group({
      carrierId: ['', Validators.required],
      trailerUnit: ['', Validators.required],
      vin: ['', Validators.required],
      make: ['', Validators.required],
      year: ['', Validators.required],
      trailerTypeCode: [''],
      ownershipCode: [''],
      country: [{alpha2Code: "US"}],
      stateProvinceCode: [null],
      plateExpirationDate: [''],
      plateNumber: [''],
    });
  }
  public carriers: Array<any> = new Array<any>();
  public yearsArr: Array<any> = new Array<any>();  public trailerTypeCodeArr: Array<Item> = new Array<Item>();
  public ownershipCodeArr: Array<Item> = new Array<Item>();
  public modelArr: Array<Item> = new Array<Item>();
  public trailerData: any = {};
  public data: any;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  protected override showAPopUp(data: any): void {
    this.data = data.data;
  }

  ngOnInit(): void {
    this.initializePopupData();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  };

  initializePopupData() {
    if(!this.data.createTrailer) {
      this.loadService.getTrailerById(this.data.data.trailerId).then(data=> {
        this.trailerData = data;
        this.setFormData();
      }, (err) => {
        this.setTouchedForm();
      });
    } else {
      this.setTouchedForm();
    }
    
    this.trailerTypeCodeArr = this.data.trailerType;
    this.ownershipCodeArr = this.data.ownerType;
    this.modelArr = this.data.makeType;
    this.carriers = this.data.carriers;

    const currYear = new Date().getFullYear();
    for (let i = currYear - 50; i <= currYear; i++) {
      const element = {value: i.toString(), viewValue: i.toString()};
      this.yearsArr.push(element);
    }
    this.trailerForm.controls['country'].valueChanges.subscribe((country)=>this.stateProvinceCodeHandler(country));
  }

  stateProvinceCodeHandler(country: any) {
    if(country && country.alpha2Code) this.states = this.getStatesByCountry(country);
    else this.states.length = 0;
    // if(this.states.length === 0) {
    //   this.trailerForm.get('stateProvinceCode')?.clearValidators();
    //   this.trailerForm.get('stateProvinceCode')?.patchValue(undefined);
    // }
    // else this.trailerForm.get('stateProvinceCode')?.setValidators([Validators.required]);
    // this.trailerForm.get('stateProvinceCode')?.updateValueAndValidity();
  }

  setFormData() {
    let selectedCountry: SelectedCountry = {alpha2Code: this.trailerData.countryCode};
    this.trailerForm.setValue({
      carrierId: this.trailerData.carrierId,
      trailerUnit: this.trailerData.trailerUnit,
      vin: this.trailerData.vin,
      make: this.trailerData.make,
      year: this.trailerData.year,
      trailerTypeCode: this.trailerData.trailerTypeCode,
      ownershipCode: this.trailerData.ownershipCode,
      country: selectedCountry.alpha2Code.length > 0 ? selectedCountry : {alpha2Code: "US"},
      stateProvinceCode: {isoCode: this.trailerData.stateProvinceCode},
      plateNumber: this.trailerData.plateNumber,
      plateExpirationDate: this.trailerData.plateExpirationDate ? new Date(this.trailerData.plateExpirationDate) : '',
    });
    this.setTouchedForm();
  }

  setTouchedForm() {
    this.trailerForm.markAllAsTouched();    
    this.trailerForm.markAsPristine(); 
  }

  deactivateClick() {
    this._result.next({type:"deactivate", data: this.data});
  }

  getStatesByCountry(country: SelectedCountry) {
    return State.getStatesOfCountry(country.alpha2Code);
  }
  
  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.dialogRef.close();
  }

  okBtnClick() {
    this._result.next({type:"ok", formData: this.trailerForm.value});
  }

  isStateLabelVisible(): boolean {
    return !this.trailerForm.get('stateProvinceCode')?.value || !this.states.some(s=>s.isoCode === this.trailerForm.get('stateProvinceCode')?.value);
  }

  isCarrierLabelVisible(): boolean {
    return !this.trailerForm.get('carrierId')?.value || !this.carriers.some(s=>s.id === this.trailerForm.get('carrierId')?.value);
  }

  isYearLabelVisible(): boolean {
    return !this.trailerForm.get('year')?.value || !this.yearsArr.some(s=>s.value === this.trailerForm.get('year')?.value);
  }

  isTrailerTypeLabelVisible(): boolean {
    return !this.trailerForm.get('trailerTypeCode')?.value || !this.trailerTypeCodeArr.some(s=>s.code === this.trailerForm.get('trailerTypeCode')?.value);
  }

  isMakeTypeLabelVisible(): boolean {
    return !this.trailerForm.get('make')?.value || !this.modelArr.some(s=>s.code === this.trailerForm.get('make')?.value);
  }

  isOwnershipLabelVisible(): boolean {
    return !this.trailerForm.get('ownershipCode')?.value || !this.ownershipCodeArr.some(s=>s.code === this.trailerForm.get('ownershipCode')?.value);
  }
}
