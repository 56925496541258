import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserRolesCode } from '../../../../../../enum/user-role.enum';
import { Carrier } from '../../../../../../_models/object/carrier';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-resources-popup.component.html',
  styleUrls: ['./filter-resources-popup.component.scss'],
})
export class FilterResourcesPopupComponent implements OnInit {

  @Input() filterResOverlay!: OverlayPanel;
  @Input() allCarriers: any[] = [];

  @Input() selectedSortingByDriver: number | null = null;
  @Output() selectedSortingByDriverChange = new EventEmitter<number | null>();

  @Input() selectedSortingByDispather: number | null = null;
  @Output() selectedSortingByDispatherChange = new EventEmitter<number | null>();

  @Input() showAllDriversOfSupervisor: any = {};

  @Output() resetFilterEmit = new EventEmitter<void>();
  @Output() applySortingEmit = new EventEmitter<any>();
  @Output() selectedCarriersEmit = new EventEmitter<any>();

  constructor(private jwtHelper: JwtHelperService) { }

  public isVisibleSuperSettings: boolean = false;

  ngOnInit(): void {
    this.isSuperVisorRole()
  }

  resetFilter() {
    this.selectedSortingByDriver = null;
    this.selectedSortingByDispather = null;
    this.showAllDriversOfSupervisor.value = false;
    this.resetFilterEmit.emit();
  }

  isSuperVisorRole() {
    const token = localStorage.getItem('token');
    const decodedToken: any = this.decodeToken(token);
    if(!decodedToken) this.isVisibleSuperSettings = false;
    else {
      this.showAllDriversOfSupervisor.superID = decodedToken.userId;
      this.isVisibleSuperSettings = decodedToken.role.join() === UserRolesCode.SUPERVISOR;
    }
  }

  decodeToken(token: string | null): string | null {
    if (!token) {
      return null; // Token doesn't exist
    }
    try {
      // Decode the token
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken;
    } catch (error) {
      console.error('Failed to decode token', error);
      return null; // Invalid token
    }
  }

  isAnyCarriersSelected(): boolean {
    return this.allCarriers.some((carrier: Carrier) => carrier.$selectedCarrier);
  }

  getSortClass(radioValue: any): string {
    return radioValue == 1 ? 'pi-sort-alpha-down-alt rotate' : 'pi-sort-alpha-up';
  }  

  applySorting() {
    this.applySortingEmit.emit();
  }

  selectedCarriers(event: any, carrierName: string) {
    this.selectedCarriersEmit.emit({event, carrierName});
  }

  // Emiterea modificărilor pentru banana in'da box
  updateSortingByDriver(value: number | null) {
    this.selectedSortingByDriverChange.emit(value);
  }

  updateSortingByDispather(value: number | null) {
    this.selectedSortingByDispatherChange.emit(value);
  }

  updateShowAllDrivers(value: boolean) {
    this.showAllDriversOfSupervisor.value = value;
    // this.showAllDriversOfSupervisorChange.emit(showAllDriversOfSupervisor);
  }

}
