<div class="driver-filter">
    <div class="header-main-container">
        <div class="filter-title">Sort</div>
        <div class="close-icon-container">
            <i class="pi pi-times" (click)="filterResOverlay.hide()"></i> 
        </div>
    </div>

    <div class="filter-body">
        <p-accordion expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            <!-- Driver Filter -->
            <p-accordionTab [headerStyleClass]="(selectedSortingByDriver != null && selectedSortingByDriver != -1) ? 'highlighted' : ''">
                <ng-template pTemplate="header">
                    <div class="accordion-header-template">
                        <p>Driver</p>
                        <i class="pi " [ngClass]="getSortClass(selectedSortingByDriver)"></i>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="section">
                        <p-radioButton 
                            [value]="0" 
                            inputId="dSA"
                            name="driverSorting" 
                            [(ngModel)]="selectedSortingByDriver" 
                            (ngModelChange)="updateSortingByDriver($event)"
                        ></p-radioButton>
                        <label for="dSA" class="pn-label-style">Alphabetical Asc. (A -> Z)</label>
                    </div>
                    <div class="section">
                        <p-radioButton 
                            [value]="1"
                            inputId="dSZ"
                            name="driverSorting" 
                            [(ngModel)]="selectedSortingByDriver" 
                            (ngModelChange)="updateSortingByDriver($event)" 
                        ></p-radioButton>
                        <label for="dSZ" class="pn-label-style">Alphabetical Dsc. (Z -> A)</label>
                    </div>
                </ng-template>
            </p-accordionTab>            

            <!-- Dispatcher Filter -->
            <p-accordionTab [headerStyleClass]="(selectedSortingByDispather != null && selectedSortingByDispather != -1) ? 'highlighted' : ''">
                <ng-template pTemplate="header">
                    <div class="accordion-header-template">
                        <p>Dispatcher</p>
                        <i class="pi " [ngClass]="getSortClass(selectedSortingByDispather)"></i> 
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="section">
                        <p-radioButton 
                            [value]="0"
                            inputId="diSA"
                            name="dispatcherSorting" 
                            [(ngModel)]="selectedSortingByDispather" 
                            (ngModelChange)="updateSortingByDispather($event)" 
                        ></p-radioButton>
                        <label for="diSA" class="pn-label-style">Alphabetical Asc. (A -> Z)</label>
                    </div>
                    <div class="section">
                        <p-radioButton 
                            [value]="1"
                            inputId="diSZ"
                            name="dispatcherSorting" 
                            [(ngModel)]="selectedSortingByDispather" 
                            (ngModelChange)="updateSortingByDispather($event)" 
                        ></p-radioButton>
                        <label for="diSZ" class="pn-label-style">Alphabetical Dsc. (Z -> A)</label>
                    </div>
                </ng-template>
            </p-accordionTab>

            <!-- Company Filter -->
            <p-accordionTab [headerStyleClass]="isAnyCarriersSelected() ? 'highlighted' : ''">
                <ng-template pTemplate="header">
                    <div class="accordion-header-template">
                        <p>Company</p>
                        <i class="pi pi-sort-alpha-up"></i> 
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="company-container">
                        <div *ngFor="let company of allCarriers; let i = index" class="filter-checkbox">
                            <p-checkbox 
                                [binary]="true" 
                                [inputId]="'cb' + i"
                                [(ngModel)]="company.selectedCarrier" 
                                (onChange)="selectedCarriers($event, company.name)"
                            ></p-checkbox>
                            <label [for]="'cb' + i" class="pn-label-style">{{ company.name }}</label>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>

            <!-- Supervisor Filter -->
            <p-accordionTab *ngIf="isVisibleSuperSettings" [headerStyleClass]="showAllDriversOfSupervisor.value ? 'highlighted' : ''">
                <ng-template pTemplate="header">
                    <div class="accordion-header-template">
                        <p>Supervisor</p> 
                        <i class="pi pi-truck"></i> 
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="super-container">
                        <section class="filter-checkbox">
                            <p-checkbox 
                                [binary]="true" 
                                inputId="cbs"
                                [(ngModel)]="showAllDriversOfSupervisor.value" 
                                (onChange)="updateShowAllDrivers($event.checked)"
                            >
                            </p-checkbox>
                            <label for="cbs" class="pn-label-style">Show only my drivers</label>
                        </section>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
    <!-- Filter Buttons -->
    <div class="filter-btns-container">
        <p-button class="filter-btns clear" outlined severity="secondary" (onClick)="resetFilter()" label="Clear"></p-button>
        <p-button class="filter-btns apply" (onClick)="applySorting()" label="Apply"></p-button> 
    </div>
</div>