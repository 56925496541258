import { Copyable } from '../index';

export class LoadInfoAdress implements Copyable<LoadInfoAdress> {

	private fullAddress: string;
	private countryCode: string;
	private stateCode: string;
	private city: string;
	private zipCode: string;
	private street: string;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.fullAddress = obj && obj.fullAddress || "";
		this.countryCode = obj && obj.countryCode || "";
		this.stateCode = obj && obj.stateCode || "";
		this.city = obj && obj.city || "";
		this.zipCode = obj && obj.zipCode || "";
		this.street = obj && obj.street || "";
	}

	copy(context: Map<Object, Object>): LoadInfoAdress{
		if (context.get(this) == null) {
			let copy : LoadInfoAdress = new LoadInfoAdress ();
			context.set(this, copy);

			copy.$fullAddress = this.$fullAddress;
			copy.$countryCode = this.$countryCode;
			copy.$stateCode = this.$stateCode;
			copy.$city = this.$city;
			copy.$zipCode = this.$zipCode;
			copy.$street = this.$street;
		}

		return <LoadInfoAdress> context.get(this);
	}

    /**
     * Getter $fullAddress
     * @return {string}
     */
	public get $fullAddress(): string {
		return this.fullAddress;
	}

    /**
     * Getter $countryCode
     * @return {string}
     */
	public get $countryCode(): string {
		return this.countryCode;
	}

    /**
     * Getter $stateCode
     * @return {string}
     */
	public get $stateCode(): string {
		return this.stateCode;
	}

    /**
     * Getter $city
     * @return {string}
     */
	public get $city(): string {
		return this.city;
	}

    /**
     * Getter $zipCode
     * @return {string}
     */
	public get $zipCode(): string {
		return this.zipCode;
	}

    /**
     * Getter $street
     * @return {string}
     */
	public get $street(): string {
		return this.street;
	}

    /**
     * Setter $fullAddress
     * @param {string} value
     */
	public set $fullAddress(value: string) {
		this.fullAddress = value;
	}

    /**
     * Setter $countryCode
     * @param {string} value
     */
	public set $countryCode(value: string) {
		this.countryCode = value;
	}

    /**
     * Setter $stateCode
     * @param {string} value
     */
	public set $stateCode(value: string) {
		this.stateCode = value;
	}

    /**
     * Setter $city
     * @param {string} value
     */
	public set $city(value: string) {
		this.city = value;
	}

    /**
     * Setter $zipCode
     * @param {string} value
     */
	public set $zipCode(value: string) {
		this.zipCode = value;
	}

    /**
     * Setter $street
     * @param {string} value
     */
	public set $street(value: string) {
		this.street = value;
	}

}