import { Copyable } from '../index';

export class Entity implements Copyable<Entity> {

    private id: number;
    private name: string;

    constructor();
	constructor(obj: Object);
	constructor(obj: object);
    constructor(obj?: any) {
        this.id = obj && obj.id || 0;
        this.name = obj && obj.name || "";
    }

    copy(context: Map<Object, Object>): Entity {
        if (context.get(this) == null) {
			let copy : Entity = new Entity();
			context.set(this, copy);

			this.copyParent(copy);
		}

		return <Entity> context.get(this);
    }

	copyParent(copy : Entity) {
		copy.$id = this.$id;
		copy.name = this.name;
	}

	public get $id(): number {
		return this.id;
	}

	public set $id(value: number) {
		this.id = value;
	}

	public get $name(): string {
		return this.name;
	}

	public set $name(value: string) {
		this.name = value;
	}
}