import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedTimeService {
  private timelineSource = new BehaviorSubject<any>(7);
  public timelineArray = this.timelineSource.asObservable();

  private timelineMonthSource = new BehaviorSubject<any>([]);
  public timelineMonthArray = this.timelineMonthSource.asObservable();

  private timelineShiftSource = new BehaviorSubject<any>([]);
  public timelineShiftArray = this.timelineShiftSource.asObservable();

  private startDateTime = new BehaviorSubject<any>('');
  public startDateTimeString = this.startDateTime.asObservable();

  private endDateTime = new BehaviorSubject<any>('');
  public endtDateTimeString = this.endDateTime.asObservable();

  constructor(
    private storageService: StorageService
  ) { }

  setTimeForTimeline(newData: any) {
    this.timelineSource.next(newData);
    if(newData === 'Current week') this.storageService.updateStorageField('currWeek', true);
    else this.storageService.updateStorageField('currWeek', false);
  }

  getTimeForTimeline() {
    return this.timelineSource.value;
  }

  setTimeForMonth(month: string) {
    if (month === 'month') {
      this.timelineMonthSource.next(month);
      this.storageService.updateStorageField('currWeek', false)
    }
  }
  setShiftTime(count: number) {
    this.timelineShiftSource.next(count);
  }

  setStartAndEndTime(start: any, end: any) {
    this.startDateTime.next(start);
    this.endDateTime.next(end);
    this.storageService.updateStorageField('start', start)
    this.storageService.updateStorageField('end', end)
  }

  getStartAndEndTime() {
    return { start: this.startDateTime.value, end: this.endDateTime.value };
  }

  getMonthForTimeline() {
    return this.timelineMonthSource.value;
  }
}
