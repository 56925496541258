<mbsc-eventcalendar #eventcalendar 
  themeVariant="light" 
  mbsc-start-icon="prev" 
  (onEventDragStart)="dragStart($event)"
  (onEventDragEnd)="dragEnd($event)" 
  [view]="view"
  [data]="myEvents" 
  [scheduleEventTemplate]="eventTemp" 
  [resources]="myResources"
  [resourceTemplate]="resourceTemp"
  [colors]="weekendCellsColor" 
  [dragToCreate]="true" 
  [invalid]="invalid" 
  [sidebarHeaderTemplate]="!settingsFormHeader?.hideTotalBy ? customTemplateHeader : ''"
  [sidebarTemplate]="!settingsFormHeader?.hideTotalBy ? customTemplate : ''" 
  [(selectedDate)]="selectedDate"
  [options]="calendarOptions" 
  [resourceHeaderTemplate]="headerTemp"
  [monthTemplate]="monthTemp"
  [dayTemplate]="dayTemplateHTML"
>

  <ng-template #monthTemp let-day>
    <div class="month-template">
        <i class="pi pi-calendar"></i>
        <span class="month-text"> {{getMonthYear(day.date)}} </span> 
    </div>
  </ng-template>

  <!-- Timeline weekdays Template -->
  <ng-template #dayTemplateHTML let-date>
    <div class="custom-day" [ngClass]="date.isActive ? 'active-custom-day' : 'custom-day'">
      <div>
        <span class="day-event">{{getDateMonth(date.date)}}</span>
        <span class="day-number">{{getDateDay(date.date)}}</span>
      </div>
      <span *ngIf="date.isActive"class="time-event">{{browserTime}}</span>
    </div>
  </ng-template>

  <!-- Loads Template -->
  <ng-template #eventTemp let-event>
    <div *ngIf="isValidEvent(event)" class="event" [ngStyle]="getEventStyles(event)" (click)="editLoad(event)">
      <div *ngIf="event.original.type === 'Load' && settingsFormHeader?.showLoadId" class="head-costom-load">
        <span class="head-constom-load-load-title"> {{ "#" + event.original.title }} </span>
        <ng-container *ngFor="let icon of getEventIcons(event)">
          <span class="head-constom-load-load-icons">
            <img *ngIf="icon.src" [src]="icon.src" />
            <span *ngIf="icon.text" class="head-constom-load-load-note">{{ icon.text }}</span>
          </span>
        </ng-container>
      </div>
  
      <div *ngIf="event.original.type === 'Load'" class="custom-load">
        
        @if(event.original.moreThanOneDay) {
          <div class="event-location">
            <span *ngIf="event.original.firstPickup">
              {{ event.original.firstPickup.$city + ', ' + event.original.firstPickup.$stateCode }}
            </span>
            <span *ngIf="event.original.firstPickup && event.original.lastDelivery">-></span>
            <span *ngIf="event.original.lastDelivery">
              {{ event.original.lastDelivery.$city + ', ' + event.original.lastDelivery.$stateCode }}
            </span>
          </div>
        } @else {
          @if(!isLoadDurationLessThanFourHours(event.original)) {
            <div class="event-location">
              <span *ngIf="event.original.firstPickup">
                {{event.original.firstPickup.stateCode}}
              </span>
              <span *ngIf="event.original.firstPickup && event.original.lastDelivery">-></span>
              <span *ngIf="event.original.lastDelivery">
                {{event.original.lastDelivery.stateCode}}
              </span>
            </div>
          }
        }
        <!-- {{show(settingsFormHeader)}} -->
        <!-- @if(!isLoadDurationLessThanFourHours(event.original)) { -->
          <div class="event-detail" >
            <span *ngIf="settingsFormHeader?.showPricePerLoad" class="event-detail-item">
              <span>{{ "$" }}</span>
              {{ event.original.price }}
            </span>
            <span *ngIf="settingsFormHeader?.showMiles" class="event-detail-item">
              {{ convertNumber(event.original.totalMiles) }} <span>{{ "mi" }}</span>
            </span>
            <span *ngIf="settingsFormHeader?.showRatePerMiles" class="event-detail-item">
              {{ event.original.ratePerMile | number : '1.2-2' }} <span>{{ "$/mi" }}</span>
            </span>
          </div>
        <!-- } -->
      </div>
    </div>
  
    <div *ngIf="event.original.status === 'TONU'" class="event-tonu mbsc-schedule-event mbsc-ltr tonu-style" (click)="editLoad(event)"></div>
  
    <div (click)="editNote(event)" *ngIf="event.original.type === 'Note'" class="event note-style">
      <div class="head-costom-load">
        <span class="head-constom-load-load-title">
          {{ "Reason: " + event.original?.reason }}
        </span>
      </div>
      <div class="custom-load">
        <div *ngIf="event.original?.reason === 'Other'" class="other-reason">
          <p>{{ event.original.otherReason }}</p>
        </div>
      </div>
    </div>

  </ng-template>

  <!-- Drivers Header Template -->
  <ng-template #headerTemp>
    <div class="drivers-header" (click)="openFilterMenu(filterResOverlay, $event)">
      <div class="filter-icon-state">
        <div class="filter-circle " [ngClass]="filterStateOn ? 'show' : ''" ></div>
        <svg-icon src="assets/svg/filter-img.svg"></svg-icon>
      </div>
      <span class="driver-filter-text">Driver List</span>
    </div>
  </ng-template>
 
  <!-- Drivers List Template -->
  <ng-template #resourceTemp let-resource>
    <div class="resource-container" (click)="openNotePopup(resource)">
      <div class="initials" *ngIf="resource.driverFullName">
        {{ getInitials(resource.driverFullName) }}
      </div>

      <div class="names">
        <div class="md-resource-header-template-name driver-name text-style-name"
          [attr.data-driver-id]="resource.driverId">
          <span class="overflow-ellipsis">{{ resource.driverFullName }}</span>

          @if(resource.coDriverId && resource.coDriverId !== 0) {
            <span class="badge" [pTooltip]="'Co-Driver: ' + resource.coDriverFullName" tooltipPosition="top" positionLeft="-4">
              {{ "+1" }}
            </span>
          }

          @switch (resource.driverStatus) {
            @case (DriverStatus.unassignedDrivers) {
              <svg-icon src="assets/svg/reassign-icon.svg" pTooltip="Reassigned Driver" tooltipPosition="top" positionLeft="-4"></svg-icon>
            }
            @case (DriverStatus.teamDrivers) {
              <svg-icon src="assets/svg/team-driver-icon.svg" pTooltip="Team Driver" tooltipPosition="top" positionLeft="-4"></svg-icon>
            }
            @case (DriverStatus.splitDrivers) {
              <svg-icon src="assets/svg/split-icon.svg" pTooltip="Split Driver" tooltipPosition="top" positionLeft="-4"></svg-icon>
            }
            @case (DriverStatus.sharedDrivers) {
              <svg-icon src="assets/svg/share-icon.svg" pTooltip="Shared Driver" tooltipPosition="top" positionLeft="-4"></svg-icon>
            }
            
            @case (DriverStatus.ownedDrivers) {}

            @case (DriverStatus.inactiveDrivers) {
              <svg-icon src="assets/svg/disabled-user-icon.svg" pTooltip="Deleted Driver" tooltipPosition="top" positionLeft="-4"></svg-icon>
            }
          }

          <svg-icon class="edit-icon-under" src="assets/svg/pencil-edit-underline.svg"
            (click)="editDriver(resource, $event)">
          </svg-icon>
        </div>

        <ng-container *ngIf="settingsFormHeader?.showTruck; else carrierBlock">
          <div class="md-resource-header-template-name text-style">
            <span class="overflow-ellipsis">{{ resource.vehicleUnit }}</span>
            <svg-icon class="edit-icon-under" src="assets/svg/pencil-edit-underline.svg"
              (click)="editVehicleClick(resource, $event)">
            </svg-icon>
          </div>
        </ng-container>

        <ng-template #carrierBlock>
          <div class="md-resource-header-template-name text-style">
            <span class="overflow-ellipsis">{{ resource.carrierName }}</span>
            <svg-icon class="edit-icon-under" src="assets/svg/pencil-edit-underline.svg"
              (click)="editCarrier(resource, $event)">
            </svg-icon>
          </div>
        </ng-template>

        <ng-container *ngIf="settingsFormHeader?.showTrailer; else dispatcherBlock">
          <div class="md-resource-header-template-name text-style">
            <span class="overflow-ellipsis">{{ resource.trailerUnit }}</span>
            <svg-icon class="edit-icon-under" src="assets/svg/pencil-edit-underline.svg"
              (click)="editTrailer(resource, $event)">
            </svg-icon>
          </div>
        </ng-container>

        <ng-template #dispatcherBlock>
          <div class="md-resource-header-template-name text-style">
            <span class="overflow-ellipsis">{{ resource.dispatcherFullName }}</span>
            <svg-icon class="edit-icon-under" src="assets/svg/pencil-edit-underline.svg"
              (click)="editDispatcher(resource, $event)">
            </svg-icon>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  
  <!-- TotalBy DropDown  -->
  <ng-template #customTemplateHeader>
    <p-button 
      styleClass="total-by-btn" 
      [outlined]="true" 
      [loading]="totalByloading"
      loadingIcon="pi pi-spinner"
      severity="secondary" 
      (onClick)="totalByBtn.toggle($event)" 
      label="Total By" 
      icon="pi pi-chevron-down" 
      iconPos="right"
    >
    </p-button>
    <p-tieredMenu #totalByBtn [model]="totalByItems" [popup]="true" appendTo="body" styleClass="totalby-list"></p-tieredMenu>
  </ng-template>

  <!-- TotalBy Details  -->
  <ng-template #customTemplate let-resource>
    <div class="total-hover" (click)="addCalcLoad(resource)">
      <div class="side-bar-font">
        <span class="f-w-600">{{ "$"}}{{convertNumber(resource.price) }}</span>
      </div>
      <div class="side-bar-font">
        <span class="f-w-600">{{ convertNumber(resource.loadMiles) }}</span>{{ " mi" }}
      </div>
      <div class="side-bar-font">
        <span class="f-w-600">{{ resource.rate.toFixed(2) }}</span>{{ " $/mi" }}
      </div>
    </div>
  </ng-template>

</mbsc-eventcalendar>

<div class="timeline-footer-line">
  <div style="padding-right: 10px">
    <span class="total-info">${{ totalPrice }}</span>
    {{ " | " }}
    <span class="total-info">{{ totalMi }}</span>
    mi
    {{ " | " }}
    <span class="total-info">{{ totalRatePerMi?.toFixed(2) }}</span>
    $/mi
  </div>
</div>

<mbsc-popup #popup [anchor]="anchor" [options]="popupOptions" (onClose)="onPopupClose()">
  <div class="load-info-popup" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" *ngIf="toolTipImformation">
    <div class="top-container">
      <div class="left-container">
        <span class="d-name">{{toolTipImformation.driverName}}</span>
        <span class="c-name">{{toolTipImformation.carrierName}}</span>
      </div>
      <div class="right-container">
        <span class="custom-loadid"> {{ "#" + toolTipImformation.customerLoadId }} </span>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-container">
        <div class="label-value">
          <span class="label">{{'Status'}}</span>
          <span class="value status">
            @if(toolTipImformation) {
              <p-dropdown 
                  #statusDrop
                  [options]="statusOptions"
                  (onChange)="selectStatus(toolTipImformation.loadId)"
                  [(ngModel)]="selectedStatus"
                  optionLabel="name" 
                  >
                  <ng-template pTemplate="selectedItem">
                    <div class="selected-status-item" *ngIf="selectedStatus">
                      <div [style.color]="checkLoadStatus(selectedStatus.$name)">{{ selectedStatus.$name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-status pTemplate="item">
                    <div class="status-item-container">
                        <div class="status-item" [style.color]="checkLoadStatus(status.name)">
                          {{ status.name }}
                        </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="dropdownicon">
                    <span class="pi pi-chevron-down" [style.color]="checkLoadStatus(selectedStatus.$name)"></span>
                  </ng-template>
              </p-dropdown>
          }
          </span>
        </div>

        <div class="label-value">
          <span class="label"></span>
          <span class="value"></span>
        </div>

        <div class="line-divider"></div>
        <div class="label-value">
          <span class="label">Pickup:</span>
          <span class="value">{{ toolTipImformation.pickUp }}</span>
        </div>
        <div class="label-value">
          <span class="label">Loaded miles:</span>
          <span class="value">{{ convertNumber(toolTipImformation.miles) }}</span>
        </div>
        <div class="label-value">
          <span class="label">D/H:</span>
          <span class="value">{{ convertNumber(toolTipImformation.dh) }}</span>
        </div>
        <div class="label-value">
          <span class="label">Total miles:</span>
          <span class="value">{{ convertNumber(toolTipImformation.totalMiles) }}</span>
        </div>
        
      </div>
      <div class="right-container">
        <div class="label-value">
          <span class="label">Price:</span>
          <span class="value">{{ convertNumber(toolTipImformation.price) }}</span>
        </div>

        <div class="label-value">
          <span class="label"></span>
          <span class="value"></span>
        </div>

        <div class="label-value">
          <span class="label">Delivery:</span>
          <span class="value">{{ toolTipImformation.delivery }}</span>
        </div>
        <div class="label-value">
          <span class="label">Rate per mile:</span>
          <span class="value">{{ toolTipImformation.rate }}</span>
        </div>
        <div class="label-value">
          <span class="label">Fee:</span>
          <span class="value fee">
            <span class="info">{{toolTipImformation.fee }}</span>
            <span class="badge" *ngIf="toolTipImformation.feesLength > 1">
              {{ "+" + (toolTipImformation.feesLength - 1) }}
            </span>
          </span>
        </div>
        <div class="label-value">
          <span class="label">Duration:</span>
          <span class="value">{{ toolTipImformation.duration }}</span>
        </div>
      </div>
    </div>
  </div>
</mbsc-popup>

<div class="overlay-filter" [class.show]="isMenuOpen">
  <p-overlayPanel #filterResOverlay (onHide)="closeMenu(filterResOverlay)" (onShow)="adjustOverlayPosition()" styleClass="filter-overlay">
    @if(isMenuOpen) {
      <app-filter-menu 
        [allCarriers]="allCarriers" 
        [filterResOverlay]="filterResOverlay"
        
        [(selectedSortingByDriver)]="selectedSortingByDriver"
        [(selectedSortingByDispather)]="selectedSortingByDispather"
        [(showAllDriversOfSupervisor)]="showAllDriversOfSupervisor"
        
        (resetFilterEmit)="resetFilter()"
        (applySortingEmit)="applySorting(filterResOverlay)"
        (selectedCarriersEmit)="selectedCarriers($event)"
        >
      </app-filter-menu>
    }
  </p-overlayPanel>
</div>