import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AUTH_API_URL } from '../app-injection-tokens';
import { Observable } from 'rxjs';
import { Note } from '../../_models';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }

  getAllCarriers(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/carriers');
  }

  getAllNotes(): Observable<any> {
    return this.http.get<Note[]>(this.apiUrl + '/api/notes');
  }

  getAllDispatchers(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/dispatchers/all')
  }

  getAllTimelineData(yearMonth: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/api/tmlne/v2?yearMonth=${yearMonth}`);
  }
}
