import {
  Directive,
  Input,
  TemplateRef,
  HostListener,
  OnInit,
  ElementRef,
  ComponentRef,
  OnDestroy
} from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/popover/popover.component';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('appTooltip') content!: string | TemplateRef<any>;
  @Input('appTooltipPos') appTooltipPos!: ConnectedPosition[];
  private overlayRef!: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent> | null = null;
  private isTooltipHovered = false;
  private isHostHovered = false;
  private observer!: MutationObserver;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.appTooltipPos)
      .withFlexibleDimensions(false)
      .withPush(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.observer = new MutationObserver(() => {
      const styles = window.getComputedStyle(this.elementRef.nativeElement);
      if (styles.opacity === '0' || styles.display === 'none') {
        this.detachTooltip();
      }
    });

    this.observer.observe(this.elementRef.nativeElement, { attributes: true, attributeFilter: ['style'] });
  }

  @HostListener('mouseenter')
  show() {
    this.isHostHovered = true;
    if (!this.tooltipRef) {
      const tooltipPortal = new ComponentPortal(TooltipComponent);
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);

      if (typeof this.content === 'string') {
        this.tooltipRef.instance.text = this.content;
      } else {
        this.tooltipRef.instance.content = this.content;
      }

      this.tooltipRef.location.nativeElement.addEventListener(
        'mouseenter',
        this.onTooltipMouseOver.bind(this)
      );
      this.tooltipRef.location.nativeElement.addEventListener(
        'mouseleave',
        this.onTooltipMouseOut.bind(this)
      );
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.isHostHovered = false;
    setTimeout(() => {
      if (!this.isTooltipHovered && !this.isHostHovered) {
        this.detachTooltip();
      }
    }, 100);
  }

  private onTooltipMouseOver() {
    this.isTooltipHovered = true;
  }

  private onTooltipMouseOut() {
    this.isTooltipHovered = false;
    setTimeout(() => {
      if (!this.isTooltipHovered && !this.isHostHovered) {
        this.detachTooltip();
      }
    }, 100);
  }

  private detachTooltip() {
    if (this.tooltipRef) {
      this.overlayRef.detach();
      this.tooltipRef = null;
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
    this.detachTooltip();
  }
}
