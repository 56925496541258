import { DriverStatus } from '../../enum/driver-status.enum';
import { Copyable, Load } from '../index';
import { Attachment } from './attachment';

export class Driver implements Copyable<Driver> {

	private id: number;
	private driverFullName: string;
	private coDriverId: number;
	private coDriverFullName: string;
	private dispatcherId: number;
	private dispatcherFullName: string;
	private supervisorId: number;
	private carrierId: number;
	private carrierName: string;
	private vehicleId: number;
	private vehicleUnit: string;
	private trailerId: number;
	private trailerUnit: string;
	private phone: string;
	private note: string;
	private price: number;
	private loadMiles: number;
	private rate: number;
	private driverStatus: DriverStatus;

	private allLoads: Array<Load>;
	private files: Array<Attachment>;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.id = obj && obj.id || 0;
		this.driverFullName = obj && obj.driverFullName || "";
		this.coDriverId = obj && obj.coDriverId || 0;
		this.coDriverFullName = obj && obj.coDriverFullName || "";
		this.dispatcherId = obj && obj.dispatcherId || 0;
		this.dispatcherFullName = obj && obj.dispatcherFullName || "";
		this.supervisorId = obj && obj.supervisorId || 0;
		this.carrierId = obj && obj.carrierId || 0;
		this.carrierName = obj && obj.carrierName || "";
		this.vehicleId = obj && obj.vehicleId || 0;
		this.vehicleUnit = obj && obj.vehicleUnit || "";
		this.trailerId = obj && obj.trailerId || 0;
		this.trailerUnit = obj && obj.trailerUnit || "";
		this.phone = obj && obj.phone || "";
		this.note = obj && obj.note || "";

		this.price = obj && obj.price || 0;
		this.loadMiles = obj && obj.loadMiles || 0;
		this.rate = obj && obj.rate || 0;
		this.driverStatus = obj && obj.driverStatus || DriverStatus.unassignedDrivers;

		this.allLoads = obj && obj.allLoads && obj.allLoads.map((load: Load) => new Load(load));
		this.files = obj && obj.files && obj.files.map((file: Attachment) => new Attachment(file));
	}

	copy(context: Map<Object, Object>): Driver{
		if (context.get(this) == null) {
			let copy : Driver = new Driver ();
			context.set(this, copy);

			copy.$id = this.$id;
			copy.$driverFullName = this.$driverFullName;
			copy.$coDriverId = this.$coDriverId;
			copy.$coDriverFullName = this.$coDriverFullName;
			copy.$dispatcherId = this.$dispatcherId;
			copy.$dispatcherFullName = this.$dispatcherFullName;
			copy.$supervisorId = this.$supervisorId;
			copy.$carrierId = this.$carrierId;
			copy.$carrierName = this.$carrierName;
			copy.$vehicleId = this.$vehicleId;
			copy.$vehicleUnit = this.$vehicleUnit;
			copy.$trailerId = this.$trailerId;
			copy.$trailerUnit = this.$trailerUnit;
			copy.$phone = this.$phone;
			copy.$note = this.$note;
			
			copy.$price = this.$price;
			copy.$loadMiles = this.$loadMiles;
			copy.$rate = this.$rate;
			copy.$driverStatus = this.$driverStatus;

			copy.$allLoads = this.$allLoads;
			copy.$files = this.$files;
		}

		return <Driver> context.get(this);
	}

	clone(source: Driver) {
        this.$id = source.$id;
		this.$id = source.$id;
		this.$driverFullName = source.$driverFullName;
		this.$coDriverId = source.$coDriverId;
		this.$coDriverFullName = source.$coDriverFullName;
		this.$dispatcherId = source.$dispatcherId;
		this.$dispatcherFullName = source.$dispatcherFullName;
		this.$supervisorId = source.$supervisorId;
		this.$carrierId = source.$carrierId;
		this.$carrierName = source.$carrierName;
		this.$vehicleId = source.$vehicleId;
		this.$vehicleUnit = source.$vehicleUnit;
		this.$trailerId = source.$trailerId;
		this.$trailerUnit = source.$trailerUnit;
		this.$phone = source.$phone;
		this.$note = source.$note;
		
		this.$price = source.$price;
		this.$loadMiles = source.$loadMiles;
		this.$rate = source.$rate;
		this.$driverStatus = source.$driverStatus;

		this.$allLoads = source.$allLoads;
		this.$files = source.$files;
    }


    /**
     * Getter $id
     * @return {number}
     */
	public get $id(): number {
		return this.id;
	}

    /**
     * Getter $driverFullName
     * @return {string}
     */
	public get $driverFullName(): string {
		return this.driverFullName;
	}

    /**
     * Getter $coDriverId
     * @return {number}
     */
	public get $coDriverId(): number {
		return this.coDriverId;
	}

    /**
     * Getter $coDriverFullName
     * @return {string}
     */
	public get $coDriverFullName(): string {
		return this.coDriverFullName;
	}

    /**
     * Getter $dispatcherId
     * @return {number}
     */
	public get $dispatcherId(): number {
		return this.dispatcherId;
	}

    /**
     * Getter $dispatcherFullName
     * @return {string}
     */
	public get $dispatcherFullName(): string {
		return this.dispatcherFullName;
	}

    /**
     * Getter $supervisorId
     * @return {number}
     */
	public get $supervisorId(): number {
		return this.supervisorId;
	}

    /**
     * Getter $carrierId
     * @return {number}
     */
	public get $carrierId(): number {
		return this.carrierId;
	}

    /**
     * Getter $carrierName
     * @return {string}
     */
	public get $carrierName(): string {
		return this.carrierName;
	}

    /**
     * Getter $vehicleId
     * @return {number}
     */
	public get $vehicleId(): number {
		return this.vehicleId;
	}

    /**
     * Getter $vehicleUnit
     * @return {string}
     */
	public get $vehicleUnit(): string {
		return this.vehicleUnit;
	}

    /**
     * Getter $trailerId
     * @return {number}
     */
	public get $trailerId(): number {
		return this.trailerId;
	}

    /**
     * Getter $trailerUnit
     * @return {string}
     */
	public get $trailerUnit(): string {
		return this.trailerUnit;
	}

    /**
     * Getter $phone
     * @return {string}
     */
	public get $phone(): string {
		return this.phone;
	}

    /**
     * Getter $note
     * @return {string}
     */
	public get $note(): string {
		return this.note;
	}

    /**
     * Getter $price
     * @return {number}
     */
	public get $price(): number {
		return this.price;
	}

    /**
     * Getter $rate
     * @return {number}
     */
	public get $rate(): number {
		return this.rate;
	}

    /**
     * Getter $driverStatus
     * @return {DriverStatus}
     */
	public get $driverStatus(): DriverStatus {
		return this.driverStatus;
	}

    /**
     * Getter $loadMiles
     * @return {number}
     */
	public get $loadMiles(): number {
		return this.loadMiles;
	}

	/**
	 * Getter $allLoads
	 * @return {Array<Load>}
	 */
	public get $allLoads(): Array<Load> {
		return this.allLoads;
	}	
	
	/**
	 * Getter $files
	 * @return {Array<Attachment>}
	 */
	public get $files(): Array<Attachment> {
		return this.files;
	}	

    /**
     * Setter $id
     * @param {number} value
     */
	public set $id(value: number) {
		this.id = value;
	}

    /**
     * Setter $driverFullName
     * @param {string} value
     */
	public set $driverFullName(value: string) {
		this.driverFullName = value;
	}

    /**
     * Setter $coDriverId
     * @param {number} value
     */
	public set $coDriverId(value: number) {
		this.coDriverId = value;
	}

    /**
     * Setter $coDriverFullName
     * @param {string} value
     */
	public set $coDriverFullName(value: string) {
		this.coDriverFullName = value;
	}

    /**
     * Setter $dispatcherId
     * @param {number} value
     */
	public set $dispatcherId(value: number) {
		this.dispatcherId = value;
	}

    /**
     * Setter $dispatcherFullName
     * @param {string} value
     */
	public set $dispatcherFullName(value: string) {
		this.dispatcherFullName = value;
	}

    /**
     * Setter $supervisorId
     * @param {number} value
     */
	public set $supervisorId(value: number) {
		this.supervisorId = value;
	}

    /**
     * Setter $carrierId
     * @param {number} value
     */
	public set $carrierId(value: number) {
		this.carrierId = value;
	}

    /**
     * Setter $carrierName
     * @param {string} value
     */
	public set $carrierName(value: string) {
		this.carrierName = value;
	}

    /**
     * Setter $vehicleId
     * @param {number} value
     */
	public set $vehicleId(value: number) {
		this.vehicleId = value;
	}

    /**
     * Setter $vehicleUnit
     * @param {string} value
     */
	public set $vehicleUnit(value: string) {
		this.vehicleUnit = value;
	}

    /**
     * Setter $trailerId
     * @param {number} value
     */
	public set $trailerId(value: number) {
		this.trailerId = value;
	}

    /**
     * Setter $trailerUnit
     * @param {string} value
     */
	public set $trailerUnit(value: string) {
		this.trailerUnit = value;
	}

    /**
     * Setter $phone
     * @param {string} value
     */
	public set $phone(value: string) {
		this.phone = value;
	}

    /**
     * Setter $note
     * @param {string} value
     */
	public set $note(value: string) {
		this.note = value;
	}

    /**
     * Setter $price
     * @param {number} value
     */
	public set $price(value: number) {
		this.price = value;
	}

    /**
     * Setter $loadMiles
     * @param {number} value
     */
	public set $loadMiles(value: number) {
		this.loadMiles = value;
	}

    /**
     * Setter $rate
     * @param {number} value
     */
	public set $rate(value: number) {
		this.rate = value;
	}

    /**
     * Setter $driverStatus
     * @param {DriverStatus} value
     */
	public set $driverStatus(value: DriverStatus) {
		this.driverStatus = value;
	}

	/**
	 * Setter $allLoads
	 * @param {Load} value
	 */
	public set $allLoads(value: Array<Load>) {
		this.allLoads = value.map((load: Load) => new Load(load));
	}

	/**
	 * Setter $files
	 * @param {Attachment} value
	 */
	public set $files(value: Array<Attachment>) {
		this.files = value.map((file: Attachment) => new Attachment(file));
	}
}

