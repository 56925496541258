import { Copyable } from "../interface/copyable";
import { Attachment } from "./attachment";
import { Fee } from "./fee";
import { LoadInfoAdress } from "./loadInfoAdress";

export class Load implements Copyable<Load>{
	
	private id:number;
	private customerLoadId:string;
	private driverId:number;
	private status:string;
	private price:number;
	private emptyMiles:number;
	private loadMiles:number;
	private totalMiles:number;
	private ratePerMile:number;
	private duration:number;
	private firstPickup: LoadInfoAdress;
	private lastDelivery: LoadInfoAdress;
	private dateTimeLineBgn:string;
	private dateTimeLineEnd:string;
	private fees: Array<Fee>;
	private isParent:boolean;
	private hasNote:boolean;
	private isSplit:boolean;
	private type:string;

	private files: Array<Attachment>;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.id = obj && obj.id || 0;
		this.customerLoadId = obj && obj.customerLoadId || "";
		this.driverId = obj && obj.driverId || 0;
		this.status = obj && obj.status || "";
		this.price = obj && obj.price || 0;
		this.emptyMiles = obj && obj.emptyMiles || 0;
		this.loadMiles = obj && obj.loadMiles || 0;
		this.totalMiles = obj && obj.totalMiles || 0;
		this.ratePerMile = obj && obj.ratePerMile || 0;
		this.duration = obj && obj.duration || 0;
		this.firstPickup = obj && new LoadInfoAdress(obj.firstPickup) || new LoadInfoAdress();
		this.lastDelivery = obj && new LoadInfoAdress(obj.lastDelivery) || new LoadInfoAdress();
		this.dateTimeLineBgn = obj && obj.dateTimeLineBgn || "";
		this.dateTimeLineEnd = obj && obj.dateTimeLineEnd || "";
		this.fees = obj && obj.fees && obj.fees.map((fee: Fee) => new Fee(fee)) || new Array<Fee>();
		this.isParent = obj && obj.isParent || false;
		this.hasNote = obj && obj.hasNote || false;
		this.isSplit = obj && obj.isSplit || false;
		this.type = obj && obj.type || "";

		this.files = obj && obj.files && obj.files.map((file: Attachment) => new Attachment(file));
	}

	copy(context: Map<Object, Object>): Load{
		if (context.get(this) == null) {
			let copy : Load = new Load ();
			context.set(this, copy);

			copy.$id = this.$id;
			copy.$customerLoadId = this.$customerLoadId;
			copy.$driverId = this.$driverId;
			copy.$status = this.$status;
			copy.$price = this.$price;
			copy.$emptyMiles = this.$emptyMiles;
			copy.$loadMiles = this.$loadMiles;
			copy.$totalMiles = this.$totalMiles;
			copy.$ratePerMile = this.$ratePerMile;
			copy.$duration = this.$duration;
			copy.$firstPickup = this.$firstPickup;
			copy.$lastDelivery = this.$lastDelivery;
			copy.$dateTimeLineBgn = this.$dateTimeLineBgn;
			copy.$dateTimeLineEnd = this.$dateTimeLineEnd;
			copy.$fees = this.$fees;
			copy.$isParent = this.$isParent;
			copy.$hasNote = this.$hasNote;
			copy.$isSplit = this.$isSplit;
			copy.$type = this.$type;

			copy.$files = this.$files;
		}

		return <Load> context.get(this);
	}

	clone(source: Load) {
		this.$id = source.$id;
		this.$customerLoadId = source.$customerLoadId;
		this.$driverId = source.$driverId;
		this.$status = source.$status;
		this.$price = source.$price;
		this.$emptyMiles = source.$emptyMiles;
		this.$loadMiles = source.$loadMiles;
		this.$totalMiles = source.$totalMiles;
		this.$ratePerMile = source.$ratePerMile;
		this.$duration = source.$duration;
		this.$firstPickup = source.$firstPickup;
		this.$lastDelivery = source.$lastDelivery;
		this.$dateTimeLineBgn = source.$dateTimeLineBgn;
		this.$dateTimeLineEnd = source.$dateTimeLineEnd;
		this.$fees = source.$fees;
		this.$isParent = source.$isParent;
		this.$hasNote = source.$hasNote;
		this.$isSplit = source.$isSplit;
		this.$type = source.$type;

		this.$files = source.$files;
    }

    /**
     * Getter $id
     * @return {number}
     */
	public get $id(): number {
		return this.id;
	}

    /**
     * Getter $customerLoadId
     * @return {string}
     */
	public get $customerLoadId(): string {
		return this.customerLoadId;
	}

    /**
     * Getter $driverId
     * @return {number}
     */
	public get $driverId(): number {
		return this.driverId;
	}

    /**
     * Getter $status
     * @return {string}
     */
	public get $status(): string {
		return this.status;
	}

    /**
     * Getter $price
     * @return {number}
     */
	public get $price(): number {
		return this.price;
	}

    /**
     * Getter $emptyMiles
     * @return {number}
     */
	public get $emptyMiles(): number {
		return this.emptyMiles;
	}

    /**
     * Getter $loadMiles
     * @return {number}
     */
	public get $loadMiles(): number {
		return this.loadMiles;
	}

    /**
     * Getter $totalMiles
     * @return {number}
     */
	public get $totalMiles(): number {
		return this.totalMiles;
	}

    /**
     * Getter $ratePerMile
     * @return {number}
     */
	public get $ratePerMile(): number {
		return this.ratePerMile;
	}

    /**
     * Getter $duration
     * @return {number}
     */
	public get $duration(): number {
		return this.duration;
	}

    /**
     * Getter $firstPickup
     * @return {LoadInfoAdress}
     */
	public get $firstPickup(): LoadInfoAdress {
		return this.firstPickup;
	}

    /**
     * Getter $lastDelivery
     * @return {LoadInfoAdress}
     */
	public get $lastDelivery(): LoadInfoAdress {
		return this.lastDelivery;
	}

    /**
     * Getter $dateTimeLineBgn
     * @return {string}
     */
	public get $dateTimeLineBgn(): string {
		return this.dateTimeLineBgn;
	}

    /**
     * Getter $dateTimeLineEnd
     * @return {string}
     */
	public get $dateTimeLineEnd(): string {
		return this.dateTimeLineEnd;
	}

    /**
     * Getter $fees
     * @return {Array<Fee>}
     */
	public get $fees(): Array<Fee> {
		return this.fees;
	}

    /**
     * Getter $isParent
     * @return {boolean}
     */
	public get $isParent(): boolean {
		return this.isParent;
	}

    /**
     * Getter $hasNote
     * @return {boolean}
     */
	public get $hasNote(): boolean {
		return this.hasNote;
	}

    /**
     * Getter $isSplit
     * @return {boolean}
     */
	public get $isSplit(): boolean {
		return this.isSplit;
	}

    /**
     * Getter $type
     * @return {string}
     */
	public get $type(): string {
		return this.type;
	}

	/**
	 * Getter $files
	 * @return {Array<Attachment>}
	 */
	public get $files(): Array<Attachment> {
		return this.files;
	}	

    /**
     * Setter $id
     * @param {number} value
     */
	public set $id(value: number) {
		this.id = value;
	}

    /**
     * Setter $customerLoadId
     * @param {string} value
     */
	public set $customerLoadId(value: string) {
		this.customerLoadId = value;
	}

    /**
     * Setter $driverId
     * @param {number} value
     */
	public set $driverId(value: number) {
		this.driverId = value;
	}

    /**
     * Setter $status
     * @param {string} value
     */
	public set $status(value: string) {
		this.status = value;
	}

    /**
     * Setter $price
     * @param {number} value
     */
	public set $price(value: number) {
		this.price = value;
	}

    /**
     * Setter $emptyMiles
     * @param {number} value
     */
	public set $emptyMiles(value: number) {
		this.emptyMiles = value;
	}

    /**
     * Setter $loadMiles
     * @param {number} value
     */
	public set $loadMiles(value: number) {
		this.loadMiles = value;
	}

    /**
     * Setter $totalMiles
     * @param {number} value
     */
	public set $totalMiles(value: number) {
		this.totalMiles = value;
	}

    /**
     * Setter $ratePerMile
     * @param {number} value
     */
	public set $ratePerMile(value: number) {
		this.ratePerMile = value;
	}

    /**
     * Setter $duration
     * @param {number} value
     */
	public set $duration(value: number) {
		this.duration = value;
	}

    /**
     * Setter $firstPickup
     * @param {LoadInfoAdress} value
     */
	public set $firstPickup(value: LoadInfoAdress) {
		this.firstPickup = value instanceof LoadInfoAdress ? value : new LoadInfoAdress(value);
	}

    /**
     * Setter $lastDelivery
     * @param {LoadInfoAdress} value
     */
	public set $lastDelivery(value: LoadInfoAdress) {
		this.lastDelivery = value instanceof LoadInfoAdress ? value : new LoadInfoAdress(value);
	}

    /**
     * Setter $dateTimeLineBgn
     * @param {string} value
     */
	public set $dateTimeLineBgn(value: string) {
		this.dateTimeLineBgn = value;
	}

    /**
     * Setter $dateTimeLineEnd
     * @param {string} value
     */
	public set $dateTimeLineEnd(value: string) {
		this.dateTimeLineEnd = value;
	}

    /**
     * Setter $fees
     * @param {Fee} value
     */
	public set $fees(value: Array<Fee>) {
		this.fees = value.map((fee: Fee) => new Fee(fee));
	}

    /**
     * Setter $isParent
     * @param {boolean} value
     */
	public set $isParent(value: boolean) {
		this.isParent = value;
	}

    /**
     * Setter $hasNote
     * @param {boolean} value
     */
	public set $hasNote(value: boolean) {
		this.hasNote = value;
	}

    /**
     * Setter $isSplit
     * @param {boolean} value
     */
	public set $isSplit(value: boolean) {
		this.isSplit = value;
	}

    /**
     * Setter $type
     * @param {string} value
     */
	public set $type(value: string) {
		this.type = value;
	}

	/**
	 * Setter $files
	 * @param {Attachment} value
	 */
	public set $files(value: Array<Attachment>) {
		this.files = value.map((file: Attachment) => new Attachment(file));
	}
  }
  