import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { AUTH_API_URL } from '../app-injection-tokens';
import { Item } from '../../interfaces/item';
import { LoaderService } from '../loader/loader.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {

    items$ = new BehaviorSubject(new Array<Item>());

    constructor(private http: HttpClient,
        @Inject(AUTH_API_URL) private apiUrl: string,
    ) { this.getData() }

    getData() {
        this.getAllReferenceData().subscribe({
          next: (data) => {
            this.items$.next(data);
          },
          error: (error) => {
            console.error('Error fetching data:', error);
          },
          complete: () => {
            console.log('Data fetching completed.');
          }
        });
      }

    getAllReferenceData(): Observable<Array<Item>> {
        return this.http.get<Array<Item>>(this.apiUrl + '/api/reference_data/all').pipe(shareReplay(1));
    }
}