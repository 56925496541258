<div class="container">
  <div class="search-panel">
    <div class="left-container">

      <mat-form-field class="search" appearance="outline" class="custom-multi-input-cont">
        <mat-label>Search</mat-label>
        <input id="co-driver-input" matInput [(ngModel)]="searchValue" (ngModelChange)="searchInputSubject.next($event)"
          type="text">
        <svg-icon src="assets/svg/search-icon.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-multi-input-cont">
        <mat-label>Carrier</mat-label>
        <mat-select [(ngModel)]="selectedCarrier" multiple panelClass="dropDwonMultipleFixClass">
          @for (carrier of carriers; track $index) {
          <mat-option id="allOption" [value]="carrier" (click)="toggleCarrier(carrier)">
            <input [id]="'cb'+$index" class="custom-checkbox" type="checkbox" [checked]="carrier.selected"
              (change)="toggleCarrier(carrier)"><label [for]="'cb'+$index"></label>
            <label class="dl-label-style">{{ carrier.carrierName }}</label>
          </mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-multi-input-cont">
        <mat-label>Dispatcher</mat-label>
        <mat-select [(ngModel)]="selectedDispatcher" multiple panelClass="dropDwonMultipleFixClass">
          @for (dispatcher of dispatchers; track $index) {
          <mat-option id="allOption" [value]="dispatcher" (click)="toggleDispatcher(dispatcher)">
            <input [id]="'cb'+$index" class="custom-checkbox" type="checkbox" [checked]="dispatcher.selected"
              (change)="toggleDispatcher(dispatcher)"><label [for]="'cb'+$index"></label>
            <label class="dl-label-style">{{ dispatcher.name }}</label>
          </mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-multi-input-cont">
        <mat-label>Driver</mat-label>
        <mat-select [(ngModel)]="selectedDriver" multiple panelClass="dropDwonMultipleFixClass">
          @for (driver of drivers; track $index) {
          <mat-option id="allOption" [value]="driver" (click)="toggleDriver(driver)">
            <input [id]="'cb'+$index" class="custom-checkbox" type="checkbox" [checked]="driver.selected"
              (change)="toggleDriver(driver)"><label [for]="'cb'+$index"></label>
            <label class="dl-label-style">{{ driver.driverName }}</label>
          </mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-multi-input-cont">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="selectedStatus" class="status-select" multiple panelClass="dropDwonMultipleFixClass">
          <mat-select-trigger>

            @switch (true) {
            @case (selectedStatus && (selectedStatus.length === 1)) {
            <div class="status-badge">
              {{selectedStatus[0].name}}
            </div>
            }
            @case (selectedStatus && (selectedStatus.length === 2)) {
            <span class="example-additional-selection">
              <div class="status-badge">
                {{selectedStatus[0].name}}
              </div>
              <div class="status-badge">
                {{selectedStatus[1].name}}
              </div>
            </span>
            }
            @case (selectedStatus && (selectedStatus.length >= 3)) {
            <span class="example-additional-selection">
              <div class="status-badge">
                {{selectedStatus[0].name}}
              </div>
              <div class="status-badge">
                {{selectedStatus[1].name}}
              </div>
              {{'+' + selectedStatus.length}}
            </span>
            }
            @default {}
            }
          </mat-select-trigger>
          @for (stat of status; track $index) {
          <mat-option id="statusOption" [value]="stat" (click)="toggleStatus(stat)">
            <input [id]="'cb'+$index" class="custom-checkbox" type="checkbox" [checked]="stat.selected"
              (change)="toggleStatus(stat)"><label [for]="'cb'+$index"></label>
            <label class="dl-label-style">
              <div class="status-counter" [style.color]="checkLoadStatus(stat)">
                {{getLoadStatus(stat)}}
              </div>
              {{ stat.name }}
            </label>

          </mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>
    </div>
    <div class="right-container">
      <svg-icon class="cmnd-icons" src="assets/svg/clear-icon.svg" (click)="cleanFilters()"></svg-icon>
      <svg-icon class="cmnd-icons" src="assets/svg/refresh-icon.svg" (click)="refreshTimeline()"></svg-icon>

      <div class="create-drop-down">
        <button class="create-btn" (click)="openDropDown()">{{"Create"}}</button>
        <div style="margin: 10px 0;">
          <ul *ngIf="dropdownOpen" style="z-index: 10;" class="dropdown-list">
            <li *ngFor="let option of createOptions; let i = index" [attr.data-value]="option" (click)="selectOption(i)"
              class="dropdown-item">
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>