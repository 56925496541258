import { Component } from '@angular/core';

@Component({
  selector: 'app-create-load-popup',
  templateUrl: './create-load-popup.component.html',
  styleUrl: './create-load-popup.component.scss'
})
export class CreateLoadPopupComponent {

}
