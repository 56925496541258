import { Copyable } from '../index';

export class Fee implements Copyable<Fee> {

	private feePrice: number;
	private addToGross: string;
	private feeName: string;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.feePrice = obj && obj.feePrice || 0;
		this.addToGross = obj && obj.addToGross || "";
		this.feeName = obj && obj.feeName || "";
	}

	copy(context: Map<Object, Object>): Fee{
		if (context.get(this) == null) {
			let copy : Fee = new Fee ();
			context.set(this, copy);

			copy.$feePrice = this.$feePrice;
			copy.$addToGross = this.$addToGross;
			copy.$feeName = this.$feeName;
		}

		return <Fee> context.get(this);
	}

    /**
     * Getter $feePrice
     * @return {number}
     */
	public get $feePrice(): number {
		return this.feePrice;
	}

    /**
     * Getter $addToGross
     * @return {string}
     */
	public get $addToGross(): string {
		return this.addToGross;
	}

    /**
     * Getter $feeName
     * @return {string}
     */
	public get $feeName(): string {
		return this.feeName;
	}

    /**
     * Setter $feePrice
     * @param {number} value
     */
	public set $feePrice(value: number) {
		this.feePrice = value;
	}

    /**
     * Setter $addToGross
     * @param {string} value
     */
	public set $addToGross(value: string) {
		this.addToGross = value;
	}

    /**
     * Setter $feeName
     * @param {string} value
     */
	public set $feeName(value: string) {
		this.feeName = value;
	}


}