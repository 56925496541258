import { Injectable } from '@angular/core';
import { StorageTempate } from '../../modules/main/pages/timeline/types/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storageKey = 'appStorage';

  constructor() { }

  saveStorage(storage: StorageTempate): void {
    localStorage.setItem(this.storageKey, JSON.stringify(storage));
  }

  getStorage(): StorageTempate | null {
    const savedStorage = localStorage.getItem(this.storageKey);
    return savedStorage ? JSON.parse(savedStorage) : null;
  }

  updateStorageField<K extends keyof StorageTempate>(field: K, value: StorageTempate[K]): void {
    const currentStorage = this.getStorage();
    if (currentStorage) {
      currentStorage[field] = value;
      this.saveStorage(currentStorage);
    }
  }

  clearStorage(): void {
    localStorage.removeItem(this.storageKey);
  }
}
export { StorageTempate };

