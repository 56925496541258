import { Copyable } from '../index';
import { Attachment } from './attachment';

export class Carrier implements Copyable<Carrier> {

	private id: number;
	private name: string;
	private dotNumber: string;
	private mcNumber: string;
	private email: string;
	private phoneNumber: string;
	private timezoneCode: string;
	private street: string;
	private city: string;
	private stateProvinceCode: string;
	private countryCode: string;
	private zipCode: string;
	private status: boolean;
	private notes: string;
	private selectedCarrier: boolean;
	private files: Array<Attachment>;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.id = obj && obj.id || 0;
		this.name = obj && obj.name || "";
		this.dotNumber = obj && obj.dotNumber || "";
		this.mcNumber = obj && obj.mcNumber || "";
		this.email = obj && obj.email || "";
		this.phoneNumber = obj && obj.phoneNumber || "";
		this.timezoneCode = obj && obj.timezoneCode || "";
		this.street = obj && obj.street || "";
		this.city = obj && obj.city || "";
		this.stateProvinceCode = obj && obj.stateProvinceCode || "";
		this.countryCode = obj && obj.countryCode || "";
		this.zipCode = obj && obj.zipCode || "";
		this.status = obj && obj.status || false;
		this.notes = obj && obj.notes || "";
		this.selectedCarrier = obj && obj.selectedCarrier || false;
		this.files = obj && obj.files && obj.files.map((file: Attachment) => new Attachment(file));
	}

	copy(context: Map<Object, Object>): Carrier{
		if (context.get(this) == null) {
			let copy : Carrier = new Carrier ();
			context.set(this, copy);

			copy.$id = this.$id;
			copy.$name = this.$name;
			copy.$dotNumber = this.$dotNumber;
			copy.$mcNumber = this.$mcNumber;
			copy.$email = this.$email;
			copy.$phoneNumber = this.$phoneNumber;
			copy.$timezoneCode = this.$timezoneCode;
			copy.$street = this.$street;
			copy.$city = this.$city;
			copy.$stateProvinceCode = this.$stateProvinceCode;
			copy.$countryCode = this.$countryCode;
			copy.$zipCode = this.$zipCode;
			copy.$status = this.$status;
			copy.$notes = this.$notes;
			copy.$selectedCarrier = this.$selectedCarrier;
			copy.$files = this.$files;
		}

		return <Carrier> context.get(this);
	}

    /**
     * Getter $id
     * @return {number}
     */
	public get $id(): number {
		return this.id;
	}

    /**
     * Getter $name
     * @return {string}
     */
	public get $name(): string {
		return this.name;
	}

    /**
     * Getter $dotNumber
     * @return {string}
     */
	public get $dotNumber(): string {
		return this.dotNumber;
	}

    /**
     * Getter $mcNumber
     * @return {string}
     */
	public get $mcNumber(): string {
		return this.mcNumber;
	}

    /**
     * Getter $email
     * @return {string}
     */
	public get $email(): string {
		return this.email;
	}

    /**
     * Getter $phoneNumber
     * @return {string}
     */
	public get $phoneNumber(): string {
		return this.phoneNumber;
	}

    /**
     * Getter $timezoneCode
     * @return {string}
     */
	public get $timezoneCode(): string {
		return this.timezoneCode;
	}

    /**
     * Getter $street
     * @return {string}
     */
	public get $street(): string {
		return this.street;
	}

    /**
     * Getter $city
     * @return {string}
     */
	public get $city(): string {
		return this.city;
	}

    /**
     * Getter $stateProvinceCode
     * @return {string}
     */
	public get $stateProvinceCode(): string {
		return this.stateProvinceCode;
	}

    /**
     * Getter $countryCode
     * @return {string}
     */
	public get $countryCode(): string {
		return this.countryCode;
	}

    /**
     * Getter $zipCode
     * @return {string}
     */
	public get $zipCode(): string {
		return this.zipCode;
	}

    /**
     * Getter $status
     * @return {boolean}
     */
	public get $status(): boolean {
		return this.status;
	}

    /**
     * Getter $notes
     * @return {string}
     */
	public get $notes(): string {
		return this.notes;
	}

    /**
     * Getter $selectedCarrier
     * @return {boolean}
     */
	public get $selectedCarrier(): boolean {
		return this.selectedCarrier;
	}

	/**
	 * Getter $files
	 * @return {Array<Attachment>}
	 */
	public get $files(): Array<Attachment> {
		return this.files;
	}	

    /**
     * Setter $id
     * @param {number} value
     */
	public set $id(value: number) {
		this.id = value;
	}

    /**
     * Setter $name
     * @param {string} value
     */
	public set $name(value: string) {
		this.name = value;
	}

    /**
     * Setter $dotNumber
     * @param {string} value
     */
	public set $dotNumber(value: string) {
		this.dotNumber = value;
	}

    /**
     * Setter $mcNumber
     * @param {string} value
     */
	public set $mcNumber(value: string) {
		this.mcNumber = value;
	}

    /**
     * Setter $email
     * @param {string} value
     */
	public set $email(value: string) {
		this.email = value;
	}

    /**
     * Setter $phoneNumber
     * @param {string} value
     */
	public set $phoneNumber(value: string) {
		this.phoneNumber = value;
	}

    /**
     * Setter $timezoneCode
     * @param {string} value
     */
	public set $timezoneCode(value: string) {
		this.timezoneCode = value;
	}

    /**
     * Setter $street
     * @param {string} value
     */
	public set $street(value: string) {
		this.street = value;
	}

    /**
     * Setter $city
     * @param {string} value
     */
	public set $city(value: string) {
		this.city = value;
	}

    /**
     * Setter $stateProvinceCode
     * @param {string} value
     */
	public set $stateProvinceCode(value: string) {
		this.stateProvinceCode = value;
	}

    /**
     * Setter $countryCode
     * @param {string} value
     */
	public set $countryCode(value: string) {
		this.countryCode = value;
	}

    /**
     * Setter $zipCode
     * @param {string} value
     */
	public set $zipCode(value: string) {
		this.zipCode = value;
	}

    /**
     * Setter $status
     * @param {boolean} value
     */
	public set $status(value: boolean) {
		this.status = value;
	}

    /**
     * Setter $notes
     * @param {string} value
     */
	public set $notes(value: string) {
		this.notes = value;
	}

    /**
     * Setter $selectedCarrier
     * @param {boolean} value
     */
	public set $selectedCarrier(value: boolean) {
		this.selectedCarrier = value;
	}

	/**
	 * Setter $files
	 * @param {Attachment} value
	 */
	public set $files(value: Array<Attachment>) {
		this.files = value.map((file: Attachment) => new Attachment(file));
	}
}

