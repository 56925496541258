<div class="edit-carrier-form-wrapper">
    <form [formGroup]="carrierForm">
        <div class="form-caption">General Information</div>
        <div class="row-1" >
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="name" formControlName="name" placeholder="Company Name*">
                <div class="input-err" *ngIf="carrierForm.get('name')?.invalid && carrierForm.get('name')?.touched">
                    <div *ngIf="carrierForm.get('name').errors?.required">
                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Company Name is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row-2">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="mcNumber" formControlName="mcNumber" placeholder="Carrier MC Number*">
                <div class="input-err" *ngIf="carrierForm.get('mcNumber')?.invalid && carrierForm.get('mcNumber')?.touched">
                    <div *ngIf="carrierForm.get('mcNumber').errors?.required">
                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Carrier MC Number is required
                    </div>
                </div>
            </div>
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="dotNumber" formControlName="dotNumber" placeholder="DOT Number">
            </div>
        </div>
        <div class="row-1">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="street" formControlName="street" placeholder="Street*">
                <div class="input-err" *ngIf="carrierForm.get('street')?.invalid && carrierForm.get('street')?.touched">
                    <div *ngIf="carrierForm.get('street').errors?.required">
                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Street is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row-2">
            <mat-select-country
                class="select-country"
                appearance="fill"
                formControlName="country"
                [itemsLoadSize]="50"
                autocomplete="true">
            </mat-select-country>
            <mat-error class="country-err" *ngIf="carrierForm.get('country')?.invalid && carrierForm.get('stateProvinceCode')?.touched">
            <mat-error>
                <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                Country is required
            </mat-error>   
            </mat-error>

            <mat-form-field class="global-select-input">
                @if (isStateLabelVisible()) {
                    <mat-label>State/Province</mat-label>
                }
                <mat-select formControlName="stateProvinceCode" panelClass="dropDwonMultipleFixClass">
                @for (state of states; track $index) {
                    <mat-option id="allOption" [value]="state.isoCode">{{state.name}}</mat-option>
                }
                </mat-select>
                <mat-error class="input-err custom-err-select" *ngIf="carrierForm.get('stateProvinceCode')?.invalid && carrierForm.get('stateProvinceCode')?.touched">
                    <mat-error *ngIf="carrierForm.get('stateProvinceCode').errors?.required">
                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        State is required
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row-2">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="zipCode" formControlName="zipCode" placeholder="Zip/Postal Code">
            </div>
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="timezoneCode" formControlName="timezoneCode" placeholder="Time Zone">
            </div>
        </div>
        @if(attachmentTemplate){
            <ng-container *ngTemplateOutlet="attachmentTemplate;"></ng-container>
        }
    </form>
  </div>