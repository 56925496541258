import { Copyable } from '../index';

export class Attachment implements Copyable<Attachment> {

	private fileId: number;
	private loadId: number;
	private loadInfoId: number;
	private companyId: number;
	private carrierId: number;
	private driverId: number;
	private userId: number;
	private fileName: string;
	private fileLabel: string;
	private fileMime: string;
	private fileContent: string;
	private fileLink: string;

	constructor();
	constructor(obj: Object);
	constructor(obj: object);
	constructor(obj?: any) {
		this.fileId = obj && obj.fileId || 0;
		this.loadId = obj && obj.loadId || 0;
		this.loadInfoId = obj && obj.loadInfoId || 0;
		this.companyId = obj && obj.companyId || 0;
		this.carrierId = obj && obj.carrierId || 0;
		this.driverId = obj && obj.driverId || 0;
		this.userId = obj && obj.userId || 0;
		this.fileName = obj && obj.fileName || "";
		this.fileLabel = obj && obj.fileLabel || "";
		this.fileMime = obj && obj.fileMime || "";
		this.fileContent = obj && obj.fileContent || "";
		this.fileLink = obj && obj.fileLink || "";
	}

	copy(context: Map<Object, Object>): Attachment{
		if (context.get(this) == null) {
			let copy : Attachment = new Attachment ();
			context.set(this, copy);

			copy.$fileId = this.$fileId;
			copy.$loadId = this.$loadId;
			copy.$loadInfoId = this.$loadInfoId;
			copy.$companyId = this.$companyId;
			copy.$carrierId = this.$carrierId;
			copy.$driverId = this.$driverId;
			copy.$userId = this.$userId;
			copy.$fileName = this.$fileName;
			copy.$fileLabel = this.$fileLabel;
			copy.$fileMime = this.$fileMime;
			copy.$fileContent = this.$fileContent;
			copy.$fileLink = this.$fileLink;
		}

		return <Attachment> context.get(this);
	}

    /**
     * Getter $fileId
     * @return {number}
     */
	public get $fileId(): number {
		return this.fileId;
	}

    /**
     * Getter $loadId
     * @return {number}
     */
	public get $loadId(): number {
		return this.loadId;
	}

    /**
     * Getter $loadInfoId
     * @return {number}
     */
	public get $loadInfoId(): number {
		return this.loadInfoId;
	}

    /**
     * Getter $companyId
     * @return {number}
     */
	public get $companyId(): number {
		return this.companyId;
	}

    /**
     * Getter $carrierId
     * @return {number}
     */
	public get $carrierId(): number {
		return this.carrierId;
	}

    /**
     * Getter $driverId
     * @return {number}
     */
	public get $driverId(): number {
		return this.driverId;
	}

    /**
     * Getter $userId
     * @return {number}
     */
	public get $userId(): number {
		return this.userId;
	}

    /**
     * Getter $fileName
     * @return {string}
     */
	public get $fileName(): string {
		return this.fileName;
	}

    /**
     * Getter $fileLabel
     * @return {string}
     */
	public get $fileLabel(): string {
		return this.fileLabel;
	}

    /**
     * Getter $fileMime
     * @return {string}
     */
	public get $fileMime(): string {
		return this.fileMime;
	}

    /**
     * Getter $fileContent
     * @return {string}
     */
	public get $fileContent(): string {
		return this.fileContent;
	}

    /**
     * Getter $fileLink
     * @return {string}
     */
	public get $fileLink(): string {
		return this.fileLink;
	}

    /**
     * Setter $fileId
     * @param {number} value
     */
	public set $fileId(value: number) {
		this.fileId = value;
	}

    /**
     * Setter $loadId
     * @param {number} value
     */
	public set $loadId(value: number) {
		this.loadId = value;
	}

    /**
     * Setter $loadInfoId
     * @param {number} value
     */
	public set $loadInfoId(value: number) {
		this.loadInfoId = value;
	}

    /**
     * Setter $companyId
     * @param {number} value
     */
	public set $companyId(value: number) {
		this.companyId = value;
	}

    /**
     * Setter $carrierId
     * @param {number} value
     */
	public set $carrierId(value: number) {
		this.carrierId = value;
	}

    /**
     * Setter $driverId
     * @param {number} value
     */
	public set $driverId(value: number) {
		this.driverId = value;
	}

    /**
     * Setter $userId
     * @param {number} value
     */
	public set $userId(value: number) {
		this.userId = value;
	}

    /**
     * Setter $fileName
     * @param {string} value
     */
	public set $fileName(value: string) {
		this.fileName = value;
	}

    /**
     * Setter $fileLabel
     * @param {string} value
     */
	public set $fileLabel(value: string) {
		this.fileLabel = value;
	}

    /**
     * Setter $fileMime
     * @param {string} value
     */
	public set $fileMime(value: string) {
		this.fileMime = value;
	}

    /**
     * Setter $fileContent
     * @param {string} value
     */
	public set $fileContent(value: string) {
		this.fileContent = value;
	}

    /**
     * Setter $fileLink
     * @param {string} value
     */
	public set $fileLink(value: string) {
		this.fileLink = value;
	}

}