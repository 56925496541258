import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../../../../services/sharedServices/shared.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { Status } from '../../../../../enum/status.enum';
import { Load } from '../types/load';
import { Driver } from '../../../../../interfaces/driver/driver';
import { Dispatcher } from '../../../../../interfaces/loads/dispatcher';
import { Carrier } from '../../../../../interfaces/carrier';
import { CreateModalAllComponent } from '../modal/create-modal-all/create-modal-all.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimelineComponent } from '../timeline.component';
import { DataService } from '../../../../../services/reference-data/reference-data.service';
import { loadColors } from '../types/load-status-color';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { LoadsService } from '../../../../../services/loads/loads.service';
// import { EditUserPopupComponent } from '../../users/components/edit-user-popup/edit-user-popup.component';
import { DialogComponent } from '../../../../../components/dialog/dialog.component';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { UserRolesCode } from '../../../../../enum/user-role.enum';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-time-line-filter',
  templateUrl: './time-line-filter.component.html',
  styleUrls: ['./time-line-filter.component.scss'],
})
export class TimeLineFilterComponent implements OnInit, OnDestroy {
  @Output() selectedTab = new EventEmitter<number>();
  @Output() refreshResource = new EventEmitter<any>();

  rangeDates: Date[] | undefined;

  subscription: Subscription;

  createOptions = ["Create Load", "Create Tonu", "Create Note"]

  copyDriver!: any[];
  selectedDriver: any;
  searchByLoad: any;
  selectedCarrier: any;
  selectedDispatcher: any;
  myResources: any;
  allDrivers: Driver[] = [];
  selectedStatus: any;
  allLoads: any;
  filteredLoad: Load[] = [];
  filteredDriver: Driver[] = [];
  dropdownOpen: boolean = false;

  public status: Array<any> = new Array<any>();
  public carriers: Array<any> = new Array<any>();
  public drivers: Array<any> = new Array<any>();
  public dispatchers: Array<any> = new Array<any>();
  private itemsSubscription: Subscription = new Subscription;
  searchInputSubject = new Subject<string>();

  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    private dataService: DataService,
    private loadService: LoadsService,
    private dialog1: DialogService,
    private notificationService: NotificationService
  ) {
    this.subscription = new Subscription();
  }
  searchValue!: any;

  ngOnInit() {
    this.sharedService.dataArray.subscribe((driver) => {
      this.allDrivers = driver
      this.drivers = [
        ...new Set(driver.map((driver) => JSON.stringify({
          driverId: driver.id,
          driverName: driver.driverFullName,
          dispatcherId: driver.dispatcherId,
          carrierId: driver.carrierId,
          selected: driver.selectedDriver
        }))),
      ];
      this.drivers = Array.from(this.drivers).map(driver => JSON.parse(driver));
      if (this.selectedDispatcher && this.selectedDispatcher.length !== 0) {
        this.filterDriversByDispatcherId(this.allDrivers)
      }
      this.selectedDrivers();
    })

    this.sharedService.dataArrayCarrier$.subscribe((carriers) => {
      this.carriers = [
        ...new Set(carriers.map((carrier) => JSON.stringify({ carrierId: carrier.id, carrierName: carrier.name }))),
      ];
      this.carriers = Array.from(this.carriers).map(carrier => JSON.parse(carrier));
    })

    this.sharedService.dataArrayDispatcher$.subscribe((disp) => {
      this.dispatchers = [
        ...new Set(disp.map((disp) => JSON.stringify({ id: disp.id, name: disp.fullName }))),
      ];
      this.dispatchers = Array.from(this.dispatchers).map(disp => JSON.parse(disp));
    })
    this.startItemsObserver();

    this.searchInputSubject
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.searchValue = value;
        this.filterLoad();
      });
  }


  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe(items => {
      items.map(item => {
        switch (item.type) {
          case 'LoadStatusLoad': this.status.push(item); break;
        }
      });
    });
  }

  checkLoadStatus(status: any) {
    if (status.code in loadColors) {
      return loadColors[status.code];
    } else {
      return '#F9FAFB';
    }
  }

  getLoadStatus = (status: any): number => {
    return this.sharedService.getLoad().filter((f: any) => f?.status === status.code).length
  }

  toggleStatus = (status: any) => {
    status.selected = !status.selected;
    this.selectedStatus = this.status.filter(s => s.selected);
    this.filterStatus();
  }

  toggleDriver = (driver: any) => {
    driver.selected = !driver.selected;
    this.selectedDriver = this.drivers.filter(s => s.selected);
    this.combinedFilter();
  }

  toggleDispatcher = (dispatcher: any) => {
    dispatcher.selected = !dispatcher.selected;
    this.selectedDispatcher = this.dispatchers.filter(s => s.selected);
    this.combinedFilter();
    this.filterDriversByDispatcherId(this.allDrivers);
  }

  toggleCarrier = (carrier: any) => {
    carrier.selected = !carrier.selected;
    this.selectedCarrier = this.carriers.filter(s => s.selected);
    this.filterDriversByCarrierId(this.allDrivers)
    this.combinedFilter();
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
    this.cleanFilters()
  }

  combinedFilter() {
    this.allDrivers = this.sharedService.getDriver();

    let driverIdsInLoads = new Set(
      this.filteredLoad.map((load) => load.driverId)
    );

    this.filteredDriver = this.allDrivers.filter((value: any) => {
      const matchesSearchValue = this.searchValue
        ? driverIdsInLoads.has(value.id)
        : true;
      const matchesCarrier =
        this.selectedCarrier && this.selectedCarrier.length > 0
          ? this.selectedCarrier.some((carrier: any) => {
            return value.carrierName?.toLowerCase().includes(carrier.carrierName.toLowerCase())
          })
          : true;
      const matchesDispatcher =
        this.selectedDispatcher && this.selectedDispatcher.length > 0
          ? this.selectedDispatcher.some((dispatcher: any) =>
            value.dispatcherFullName
              ?.toLowerCase()
              .includes(dispatcher.name.toLowerCase())
          )
          : true;
      const matchesDriver =
        this.selectedDriver && this.selectedDriver.length > 0
          ? this.selectedDriver.some((driver: any) =>
            value.driverFullName?.toLowerCase().includes(driver.driverName.toLowerCase())
          )
          : true;

      return (
        matchesCarrier &&
        matchesDriver &&
        matchesDispatcher &&
        matchesSearchValue
      );
    });
    if (
      (!this.selectedCarrier || this.selectedCarrier.length === 0) &&
      (!this.selectedDispatcher || this.selectedDispatcher.length === 0) &&
      (!this.selectedDriver || this.selectedDriver.length === 0) &&
      (!this.selectedStatus || this.selectedStatus.length === 0)
    ) {
      this.filteredDriver = [...this.allDrivers];
    }

    this.sharedService.setFilterDriver(this.filteredDriver);
  }

  filterLoad() {
    this.allLoads = this.sharedService.getLoad();
    this.filteredLoad = this.allLoads.filter((load: any) => {
      if (!load.parentId) {
        const matchesSearchValue = this.searchValue
          ? load.customerLoadId.toString().includes(this.searchValue)
          : true;
        return matchesSearchValue
      }
    });
    if (!this.searchValue) {
      this.filteredLoad = [...this.allLoads];
    }
    this.sharedService.setFilteredLoad(this.filteredLoad)
  }

  filterStatus() {
    this.allLoads = this.sharedService.getLoad();
    if (this.selectedStatus && this.selectedStatus.length !== 0) {
      this.filteredLoad = this.allLoads.filter((load: any) => {
        for (let i = 0; i < this.selectedStatus.length; i++) {
          if (load.status.toLowerCase() === this.selectedStatus[i].name?.toLowerCase()) {
            return load
          }
        }
      })
    } else {
      this.filteredLoad = [...this.allLoads]
    }

    this.sharedService.setFilteredLoad(this.filteredLoad)
  }

  filterDriversByDispatcherId(allDrivers: any): void {
    const uniqueDrivers = new Map();
    this.selectedDriver = [];
    if (this.selectedDispatcher && this.selectedDispatcher.length !== 0) {
      const selectedDispatcherIds = this.selectedDispatcher.map((d: any) => d.id);

      allDrivers.forEach((driver: any) => {
        if (selectedDispatcherIds.includes(driver.dispatcherId)) {
          uniqueDrivers.set(driver.id, {
            driverId: driver.id,
            driverName: driver.driverFullName,
            dispatcherId: driver.dispatcherId
          });
        }
      });
      this.drivers = Array.from(uniqueDrivers.values());
    } else if ((this.selectedCarrier && this.selectedCarrier.length !== 0) && !(this.selectedDispatcher && this.selectedDispatcher.length !== 0)) {
      this.filterDriversByCarrierId(allDrivers)
    } else {
      allDrivers.forEach((driver: any) => {
        uniqueDrivers.set(driver.id, {
          driverId: driver.id,
          driverName: driver.driverFullName,
          dispatcherId: driver.dispatcherId,
        });
      });
      this.drivers = Array.from(uniqueDrivers.values());
    }

  }

  filterDriversByCarrierId(allDrivers: any) {
    const uniqueDrivers = new Map();
    this.selectedDriver = [];
    if (this.selectedCarrier && this.selectedCarrier.length !== 0) {
      const selectedCarrirerIds = this.selectedCarrier.map((d: any) => d.carrierId);
      allDrivers.forEach((driver: any) => {
        if (selectedCarrirerIds.includes(driver.carrierId)) {
          uniqueDrivers.set(driver.id, {
            driverId: driver.id,
            driverName: driver.driverFullName,
            dispatcherId: driver.dispatcherId
          });
        }
      });
      this.drivers = Array.from(uniqueDrivers.values());
    } else if ((this.selectedDispatcher && this.selectedDispatcher.length !== 0) && !(this.selectedCarrier && this.selectedCarrier.length !== 0)) {
      this.filterDriversByDispatcherId(this.allDrivers)
    } else {
      allDrivers.forEach((driver: any) => {
        uniqueDrivers.set(driver.id, {
          driverId: driver.id,
          driverName: driver.driverFullName,
          dispatcherId: driver.dispatcherId,
        });
      });
      this.drivers = Array.from(uniqueDrivers.values());
    }
  }


  cleanFilters() {
    this.selectedCarrier = [];
    this.searchValue = '';
    this.selectedDispatcher = [];
    this.selectedDriver = [];
    this.selectedStatus = [];
    this.drivers.forEach(value => value.selected = false);
    this.drivers = this.setArrayToMap(this.allDrivers, this.drivers);
    this.dispatchers.forEach(value => value.selected = false);
    this.carriers.forEach(value => value.selected = false);
    this.status.forEach(value => value.selected = false);
    this.sharedService.setFilteredLoad(this.allLoads);
    this.sharedService.setFilterDriver(this.allDrivers);
  }

  refreshTimeline() {
    this.refreshResource.emit();
  }

  openDropDown() {
    this.dropdownOpen = !this.dropdownOpen
  }

  selectOption(index: number) {
    if ((index <= 2)) this.openCreateModal(index);
    // else this.openCreateDriverPopup(true);
    this.dropdownOpen = false
  }

  openCreateModal(index: number) {
    this.selectedTab.emit(index)
  }

  setArrayToMap(allDrivers: any, drivers: any) {
    let uniqueArray = new Map();

    allDrivers.forEach((driver: any) => {
      if (!uniqueArray.has(driver.id)) {
        uniqueArray.set(driver.id, {
          driverId: driver.id,
          driverName: driver.driverFullName,
          dispatcherId: driver.dispatcherId
        });
      }
    });
    drivers = Array.from(uniqueArray.values());
    return drivers
  }

  selectedDrivers() {
    if ((this.selectedDriver && this.selectedDriver?.length !== 0)) {
      this.drivers = this.drivers.map(driver => {
        for (let i = 0; i < this.selectedDriver.length; i++) {
          if (driver.driverId === this.selectedDriver[i].driverId) {
            driver.selected = this.selectedDriver[i]?.selected
          }
        }
        return driver
      })
    }
  }
}
