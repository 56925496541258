import { Component, ViewChild } from '@angular/core';
import { SettingsComponent } from '../../../../interfaces/settings-component';
import { TimeLineCalendarComponent } from './time-line-calendar/time-line-calendar.component';
import { StorageService, StorageTempate } from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent {
  @ViewChild(TimeLineCalendarComponent) timeLineCalendarComponent!: TimeLineCalendarComponent
  settingsFormHeader!: SettingsComponent

  constructor(
    private storageService: StorageService,
  ) {
    this.saveSettings();
  }

  receiveSettings(event: any) {
    this.settingsFormHeader = event
  }

  selectedTab(event: number) {
    this.timeLineCalendarComponent.createLoad(event)
  }

  refreshResource() {
    this.timeLineCalendarComponent.refreshTmlnDataResources();
  }

  saveSettings(): void {
    let storage = this.storageService.getStorage()
    if (!storage) {
      const newStorage: StorageTempate = {
        start: this.getMonday(),
        end: this.getSunday(),
        currWeek: true,
        customRangeCalendar: false,
        selectedSortingByDriver: -1,
        selectedSortingByDispather: -1,
        companySort: [],
        showAllDriversOfSupervisor: {},
        timeLineSettings: {
          showLoadId: true,
          showPricePerLoad: true,
          showMiles: true,
          showRatePerMiles: true,
          hideTotalBy: false,
          hideUnassignedLoads: false,
          showTruck: false,
          showTrailer: false,
        },
      };
      this.storageService.saveStorage(newStorage);
    }
  }

  private getMonday(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff));
  }

  private getSunday(): Date {
    const monday = this.getMonday();
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    return sunday;
  }
}
