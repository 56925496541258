import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { UsersPageService } from '../../../../../../services/users/users.service';
import { UserRolesCode } from '../../../../../../enum/user-role.enum';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss', '../../../../main.module.styles.scss']
})
export class EditUserFormComponent implements OnInit{

  @Output() formValid = new EventEmitter<any>();
  @Input() selectSuperVisor!: boolean;
  @Input() row!: any;
  @ContentChild('attachmentTemplate') attachmentTemplate?: TemplateRef<any>;
  
  constructor(
    private formBuilder: FormBuilder,
    private usersPageService: UsersPageService,
    private jwtHelper: JwtHelperService,
  ) { }

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  userForm!: FormGroup;
  
  public superVisorsSource:Array<any> = new Array<any>();
  public fieldTextType: string = 'password';
  public userRoles: Array<any> = [
    {value: 'Dispatcher', viewValue: 'Dispatcher'},
    {value: 'Supervisor', viewValue: 'Supervisor'},
    {value: 'Dispatch Admin', viewValue: 'Dispatch Admin', disabled: true},
    {value: 'Admin', viewValue: 'Admin', disabled: true},
  ];

  ngOnInit(): void {
    this.initializeForm(this.row.createUser);
    this.getSupervisors();
    this.emitFormValidity();
    if(!this.row.createUser) this.setFormData();
    const isAdminRole: boolean = this.decodeToken().role.join() === UserRolesCode.ADMIN || this.decodeToken().role.join() === UserRolesCode.DISPATCHADMIN;
    if(!isAdminRole || this.row.createUser) this.userForm.get('roles')?.disable();
    if(this.row.roles.length) this.userForm.patchValue({roles: this.row.roles.join()});
    this.userForm.markAllAsTouched();
  }

  initializeForm(isRequired: boolean): void {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      supervisorId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      roles: new FormControl(null, Validators.required),

      // Conditionally apply 'required' validator
      password: new FormControl(null, [
        ...(isRequired ? [Validators.required] : []),
        Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/),
      ]),
      password_confirm: new FormControl(null, [
        ...(isRequired ? [Validators.required] : []),
        Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/),
      ]),
    });
    
    this.userForm.valueChanges.subscribe(() => {
      this.emitFormValidity();
    });

    this.userForm.addValidators(
      this.matchPassValidator('password', 'password_confirm')
    );
  }

  setFormData() {
    this.userForm.setValue({
      firstName: this.row.firstName,
      lastName: this.row.lastName,
      supervisorId: this.row.supervisorId,
      email: this.row.email,
      phone: this.row.phone,
      roles: this.row.roles.join(),
      password: this.row.password,
      password_confirm: ''
    });
  }

  decodeToken(): any {
    const token = localStorage.getItem('token');
    if (!token) {
      return null; // Token doesn't exist
    }
    try {
      // Decode the token
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken;
    } catch (error) {
      console.error('Failed to decode token', error);
      return null; // Invalid token
    }
  }
  
  matchPassValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
        const control = abstractControl.get(controlName);
        const matchingControl = abstractControl.get(matchingControlName);

        if (control!.errors && !matchingControl!.errors?.['confirmedValidator']) {
            return null;
        }

        if ((control?.value !== matchingControl?.value) && control?.value && !matchingControl!.errors?.['confirmedValidator']) {
          const error = { confirmedValidator: 'Passwords do not match.' };
          matchingControl!.setErrors(error);
          return error;
        } else {
          matchingControl!.setErrors(null);
          return null;
        }
    }    
  }

  togglePassType() {
    this.fieldTextType = this.fieldTextType === 'password' ? 'text' : 'password';        
  }

  emitFormValidity() {
    this.formValid.emit({isValid:this.userForm.valid});
    if(this.userForm.valid) {
      let formData: any = {roles: this.userForm.get('roles')?.value, ...this.userForm.value}
      this.formValid.emit({isValid:this.userForm.valid, formData: formData});
    }
  }

  getSupervisors() {
    this.usersPageService.getSupervisors().subscribe(data => {
      this.superVisorsSource = data;
    },
    error => {
      console.error('Error fetching users', error);
    });
  }
}
